import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIService } from "@core/services/api.service";
import { BusinessInterfaceRenderService } from "@core/services/business/business.service";
import { Helper } from "@core/services/helper";
import { LoaderService } from "@core/services/loader.service";
import { Logger } from "@core/services/logger.service";
import { ToasterService } from "@core/services/toaster.service";
import { isNullOrUndefinedString } from "@services/core.utilities";
import {
  default_drop_down_button,
  summay_drop_down_buttons,
} from "@services/data.service";
import {
  DataInteractionArray,
  HiddenDataInteractionArray,
  cm_default_layer,
} from "app/layers";
import { environment } from "environments/environment";
import {
  DataInteractionClass,
  HiddenDataInteractionClass,
} from "./layers-interaction.class";

@Injectable()
export class DataInteractionService extends APIService {
  public inputsCM: any[] = [];
  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService,
    private _helper: Helper,
    private _business: BusinessInterfaceRenderService
  ) {
    super(http, logger, loaderService, toasterService);
  }
  /* getModulesServices(): Promise<ModulesService[]> {
    return this.http.get(this.servicesUrl)
      .toPromise()
      .then(response => response.json().data as ModulesService[])
      .catch(this.handleError);
  }*/
  getDataInteractionServices(): Promise<DataInteractionClass[]> {
    return Promise.resolve(
      DataInteractionArray.filter(
        (layer) =>
          layer.projects?.includes(environment.appName) ||
          layer.category == "Calculation module" ||
          //personal layer has non 0 id + only layers uploaded on same mode (localhost or dev) are shown
          (layer.id != 0 &&
            ((environment.apiUrl.includes("localhost") && layer.localhost) ||
              (!environment.apiUrl.includes("localhost") && !layer.localhost)))
      )
    );
  }
  getHiddenDataInteractionServices(): Promise<HiddenDataInteractionClass[]> {
    return Promise.resolve(HiddenDataInteractionArray);
  }

  getDataArrayServices(): DataInteractionClass[] {
    return DataInteractionArray;
  }
  layerExists(layer) {
    const arr_size = DataInteractionArray.filter(
      (x) => x.id === layer.id
    ).length;
    return arr_size !== 0;
  }
  removeLayer(id) {
    DataInteractionArray.splice(
      DataInteractionArray.findIndex((x) => x.id === id),
      1
    );
  }

  removeAllUserLayers() {
    for (let i = DataInteractionArray.length - 1; i >= 0; i--) {
      if (DataInteractionArray[i].id !== 0) {
        DataInteractionArray.splice(i, 1);
      }
    }
  }

  addNewLayer(name, id, layer_type) {
    const newLayerAdded = DataInteractionArray.push(
      Object.assign({}, DataInteractionArray[0])
    );
    DataInteractionArray[newLayerAdded - 1].name = name;
    DataInteractionArray[newLayerAdded - 1].id = id;
    DataInteractionArray[newLayerAdded - 1].layer_type = layer_type;
    DataInteractionArray[newLayerAdded - 1].workspaceName = layer_type;
    DataInteractionArray[newLayerAdded - 1].ref = [default_drop_down_button];
    DataInteractionArray[newLayerAdded - 1].category = "";
    DataInteractionArray[newLayerAdded - 1].isSelected = false;
    DataInteractionArray[newLayerAdded - 1].download_url = "";
    DataInteractionArray[newLayerAdded - 1].description = "";
  }

  addNewCMLayer(
    name,
    id,
    symbology_layer_type,
    type_of_layer,
    symb?,
    layer_id = 0
  ) {
    const newLayerAdded = DataInteractionArray.push(
      Object.assign({}, cm_default_layer)
    );
    DataInteractionArray[newLayerAdded - 1].name = name;
    DataInteractionArray[newLayerAdded - 1].workspaceName = name;
    DataInteractionArray[newLayerAdded - 1].cm_id = id; // TODO: replace name
    DataInteractionArray[newLayerAdded - 1].type_of_layer = type_of_layer;
    DataInteractionArray[newLayerAdded - 1].layer_type = symbology_layer_type;
    DataInteractionArray[newLayerAdded - 1].custom_symbology = symb;
    DataInteractionArray[newLayerAdded - 1].id = layer_id;
    this.logger.log(
      "DataInteractionService/getReadableName addNewCMLayer",
      DataInteractionArray[newLayerAdded - 1]
    );
  }
  getReadableName(layerName: string): string {
    this.logger.log(
      "DataInteractionService/getReadableName layerName",
      layerName
    );
    const layer = this.getDataArrayServices().filter(
      (x) => x.workspaceName === layerName
    )[0];
    if (isNullOrUndefinedString(layer)) {
      const layer_new = this._business.getReadableName(layerName);
      if (isNullOrUndefinedString(layer_new)) {
        return layerName;
      } else {
        return layer_new;
      }
    }

    return layer.name;
  }
  getModulesServicesWithNewService(
    newService: DataInteractionClass
  ): Promise<DataInteractionClass[]> {
    const servicesArray: DataInteractionClass[] = DataInteractionArray;
    servicesArray.push(newService);
    return Promise.resolve(servicesArray);
  }

  getModulesServicesSlowly(): Promise<DataInteractionClass[]> {
    return new Promise((resolve) => {
      // Simulate server latency with 2 second delay
      setTimeout(() => resolve(this.getDataInteractionServices()), 1000);
    });
  }

  getRefFromLayerName(name: string): any[] {
    this.logger.log(
      "layers-interaction.service/getRefFromLayerName name:",
      name
    );
    const layer = this.getLayersTabs().filter(
      (x) => x.workspaceName === name
    )[0];
    if (isNullOrUndefinedString(layer)) {
      return [default_drop_down_button];
    }
    return layer.ref;
  }

  getLayersTabs(): DataInteractionClass[] {
    return DataInteractionArray;
  }

  getSplittedResults(results) {
    this.logger.log(
      "layers-interaction.service/getSplittedResults results",
      results
    );
    let newResults = this._helper.createSplittedResultsModel();
    const rLayers = results.layers;
    this.logger.log(
      "layers-interaction.service/getSplittedResults rLayers ",
      rLayers
    );
    const rNoDataLayers = results.no_data_layers;

    // returns null if results is empty
    if (
      isNullOrUndefinedString(rLayers) &&
      isNullOrUndefinedString(rNoDataLayers)
    ) {
      return null;
    }

    for (let j = 0; j < summay_drop_down_buttons.length; j++) {
      for (let i = 0; i < rLayers.length; i++) {
        if (
          this.getRefFromLayerName(results.layers[i].name).includes(
            summay_drop_down_buttons[j]["ref"]
          )
        ) {
          const ref = summay_drop_down_buttons[j]["ref"];
          newResults[ref]["layers"].push(results.layers[i]);
          this.logger.log(
            "layers-interaction.service/getSplittedResults rLayers ",
            rLayers
          );
        }
      }
      for (let i = 0; i < rNoDataLayers.length; i++) {
        if (
          this.getRefFromLayerName(results.no_data_layers[i]).includes(
            summay_drop_down_buttons[j]["ref"]
          )
        ) {
          const ref = summay_drop_down_buttons[j]["ref"];
          newResults[ref]["no_data_layers"].push(results.no_data_layers[i]);
        }
      }
    }
    this.logger.log(
      "layers-interaction.service/getSplittedResults newResults ",
      newResults
    );
    return newResults;
  }
  getLayersFromType(layer) {
    return this.getDataInteractionServices().then((data) => {
      return data.filter((x) => x.layer_type === layer);
    });
  }
  getHiddenLayersFromType(layer) {
    return this.getHiddenDataInteractionServices().then((data) => {
      return data.filter((x) => x.layer_type === layer);
    });
  }
  setLoadingLayerInterraction(layer) {
    this.getDataInteractionServices().then((data) => {
      data.filter((x) => x.workspaceName === layer)[0].isLoading = true;
    });
  }
  unsetLoadingLayerInterraction(layer) {
    this.getDataInteractionServices().then((data) => {
      data.filter((x) => x.workspaceName === layer)[0].isLoading = false;
    });
  }
}
