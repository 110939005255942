/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 14.07.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/

import { Injectable } from "@angular/core";
import * as uikit from "uikit";

@Injectable()
export class ToasterService {
  defaultErrorMsg =
    "Something went wrong. Please try again later.\n If the error persists, please contact an administrator\n";

  showToaster(msg: string) {
    uikit.notification.closeAll();
    uikit.notification({
      message: `<span class="uk-text-break">${msg}</span>`,
      pos: "bottom-center",
      timeout: 5000,
    });
  }
  showDangerToaster(msg: string) {
    uikit.notification.closeAll();
    uikit.notification({
      message:
        "<span uk-icon='icon: warning'></span> " +
        `<span class="uk-text-break">${msg}</span>`,
      pos: "bottom-center",
      timeout: 5000,
      status: "danger",
    });
  }

  showDefaultErrorToaster(errorCode: number) {
    uikit.notification.closeAll();
    uikit.notification({
      message:
        "<span uk-icon='icon: warning'></span> " +
        `<span class="uk-text-break">Something went wrong. Please try again later.</span>
        <div>If the error persists, please contact an administrator\n</div>
        <div>Error code ${errorCode}</div>`,
      pos: "bottom-center",
      timeout: 5000,
      status: "danger",
    });
  }
  showToasterSurfaceCalculDisabled() {
    this.showToaster(
      "You have exceeded the AREA SELECTION LIMIT for the free selection as well as for running calculation modules! Please select a smaller area."
    );
  }
}
