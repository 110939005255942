import { NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Logger } from "@core/services/logger.service";
import { UploadService } from "@core/services/upload.service";
import { MapService } from "@pages/map/services/map.service";
import {
  calculation_module_category,
  hectare,
  lau2,
  nuts0,
  nuts1,
  nuts2,
  nuts3,
  urlLegend,
} from "@services/data.service";
import { DataInteractionClass } from "../layers-interaction/layers-interaction.class";

@Component({
  standalone: true,
  selector: "htm-layer-tool",
  templateUrl: "./layer-tool.component.html",
  styleUrls: ["./layer-tool.component.css"],
  imports: [NgIf],
})
export class LayerToolComponent implements OnInit {
  @Input() dataInteraction: DataInteractionClass;
  private _imageUrl = urlLegend;
  isLegendDisplayed = false;
  isInfoDisplayed = false;
  private _displayLegend = false;

  loading: boolean = false;
  hasZoneSelected: boolean = false;
  calculation_module_name = calculation_module_category;

  constructor(
    private _mapService: MapService,
    private _uploadService: UploadService,
    private _logger: Logger
  ) {}

  ngOnInit() {
    // display download button according to the following rules
    if (this._mapService.getLoadResultbuttonState()) {
      this._mapService.getLoadResultbuttonState().subscribe((value) => {
        let availableScales = [];
        if (this.dataInteraction.category === calculation_module_category) {
          availableScales = [nuts0, nuts1, nuts2, nuts3, lau2, hectare];
        } else if (this.dataInteraction.dataType) {
          if (this.dataInteraction.dataType === "raster") {
            availableScales = [nuts3, lau2, hectare];
          } else if (this.dataInteraction.dataType === "shp")
            availableScales = [nuts2, nuts3, lau2, hectare];
          else if (this.dataInteraction.dataType === "csv")
            availableScales = this.dataInteraction.scales
              ? this.dataInteraction.scales
              : [nuts2, nuts3, lau2, hectare];
          else throw Error("This dataType is not supported.");
        } else {
          this._logger.log(
            "This layer can't be downloaded: ",
            this.dataInteraction.name,
            "error"
          );
        }

        this.hasZoneSelected =
          value &&
          availableScales.indexOf(this._mapService.getScaleValue()) > -1;
      });
    }
  }

  toggleLegend() {
    this.isLegendDisplayed = !this.isLegendDisplayed;
  }
  toggleInformations() {
    this.isInfoDisplayed = !this.isInfoDisplayed;
  }

  endLoadLegend() {
    this._displayLegend = true;
  }

  export() {
    this.loading = true;
    this._uploadService.export(this.dataInteraction).then((data) => {
      if (data.url != "") {
        //window.open(data.url); //POPUP blocker
        const a = document.createElement("a");
        a.href = data.url;
        a.download = data.filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(data.url);
      }
      this.loading = false;
    });
  }
}
