import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class CookiesService {
  cookiesAcceptedKey = "cookiesAccepted";

  cookiesAccepted() {
    return localStorage.getItem("cookiesAccepted") === "true";
  }

  setCookiesAccepted() {
    localStorage.setItem("cookiesAccepted", "true");
  }
}
