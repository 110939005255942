<h2 class="uk-modal-title">REGISTER</h2>
<form [formGroup]="reactiveForm">
  <p>
    <input
      class="uk-input"
      id="form-horizontal-text1"
      type="text"
      placeholder="Firstname"
      formControlName="firstname"
      required
    />
  </p>
  <p>
    <input
      class="uk-input"
      id="form-horizontal-text2"
      type="text"
      placeholder="Lastname"
      formControlName="lastname"
      required
    />
  </p>
  <p>
    <input
      class="uk-input"
      [class.uk-form-danger]="errors.isEmailInvalid"
      id="form-horizontal-text3"
      type="email"
      placeholder="Email"
      formControlName="email"
      (blur)="onInputBlur($event)"
      required
    />
  </p>
  <p>
    <input
      class="uk-input"
      id="form-horizontal-text4"
      type="password"
      placeholder="Password"
      formControlName="password"
      required
    />
  </p>

  <p style="padding-left: 25px">
    <label>
      <input
        id="privacy-checkbox"
        class="uk-checkbox"
        type="checkbox"
        [checked]="gdpr"
        (change)="gdpr = !gdpr"
      />
      I have read and understood the
      <a href="#modal-gdpr" uk-toggle>privacy terms</a>.
    </label>
  </p>

  <div class="uk-margin-small">
    <re-captcha
      siteKey="6Ldb7zcUAAAAADpGmUYGHKDq4HnQ-WqzfqissGje"
      formControlName="recaptchaReactive"
    ></re-captcha>
  </div>
  <p>
    <button class="uk-button uk-button-default uk-modal-close" type="button">
      Cancel
    </button>
    <button
      class="uk-button uk-button-primary"
      type="button"
      [disabled]="reactiveForm.invalid || !gdpr"
      (click)="!waitingStatus ? createAccount() : null"
    >
      <span *ngIf="!waitingStatus">Create account</span>
      <span *ngIf="waitingStatus" uk-spinner></span>
    </button>
  </p>
  <ng-container *ngIf="errorsStr.length > 0">
    <div *ngFor="let error of errorsStr" class="uk-text-danger">
      {{ error }}
    </div>
  </ng-container>
</form>
