import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MapComponent } from "@pages/map/map.component";
import { MapService } from "@pages/map/services/map.service";
import { ScenarioService } from "./scenario.service";

@Component({
  standalone: true,
  selector: "app-scenariotab",
  templateUrl: "./scenariotab.component.html",
  styleUrl: "./scenariotab.component.css",
  imports: [CommonModule],
  providers: [ScenarioService],
})
export class ScenariotabComponent {
  @Input() scenarioOpen: boolean;

  public scenariosList = [];

  constructor(
    public mapComponent: MapComponent,
    public _mapService: MapService,
    private _scenarioService: ScenarioService
  ) {}

  ngOnInit() {
    this._scenarioService
      .getScenarios()
      .subscribe((scenarios) => (this.scenariosList = scenarios));
  }

  openTab(): void {
    this.scenarioOpen = !this.scenarioOpen;
    if (this.scenarioOpen) {
      this.mapComponent.setScenarioOpened();
    } else {
      this.mapComponent.setTabsClosed();
    }
  }

  closeTab(): void {
    this.scenarioOpen = false;
    this.mapComponent.setTabsClosed();
  }
}
