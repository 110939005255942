import { Component, Input, OnInit } from "@angular/core";

import { NgForOf, NgIf } from "@angular/common";
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { WaitingStatusComponent } from "@core/bases/waiting-status.base";
import { ToasterService } from "@core/services/toaster.service";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import * as uikit from "uikit";
import { UserManagementService } from "../service/user-management.service";

@Component({
  standalone: true,
  selector: "htm-register",
  templateUrl: "./register.component.html",
  imports: [
    NgIf,
    NgForOf,
    FormsModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,

    // External Module
    RecaptchaModule,
  ],
})
export class RegisterComponent
  extends WaitingStatusComponent
  implements OnInit
{
  @Input() token_activation;
  private _email = "";
  private _password = "";
  private _lastname = "";
  private _firstname = "";
  private _captcha;
  errorsStr = [];
  errors = {
    isEmailInvalid: false,
    isFirstNameInvalid: false,
    isLastNameInvalid: false,
    isPasswordInvalid: false,
  };

  gdpr: boolean = false;

  reactiveForm: UntypedFormGroup;

  constructor(
    private _userManagementService: UserManagementService,
    private _toasterService: ToasterService
  ) {
    super();
  }

  ngOnInit() {
    this.reactiveForm = new UntypedFormGroup({
      recaptchaReactive: new UntypedFormControl(null, Validators.required),
      firstname: new UntypedFormControl(null, Validators.required),
      lastname: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, Validators.required),
    });
  }

  createAccount() {
    this._setWaitingStatus(true);

    if (!this.checkInputValues()) {
      this._setWaitingStatus(false);
      return;
    }

    this._email = this.reactiveForm.get("email").value;

    const payload = {
      email: this.reactiveForm.get("email").value,
      password: this.reactiveForm.get("password").value,
      first_name: this.reactiveForm.get("firstname").value,
      last_name: this.reactiveForm.get("lastname").value,
    };
    this._userManagementService
      .userRegister(payload)
      .then((data) => {
        this._toasterService.showToaster(
          data.message +
            ". <br />An email has been sent to your mail address (" +
            this._email +
            "). <br />Please press the link in the mail to activate your account."
        );
        this.closePanel();
        this.resetProcess();
      })
      .catch(() => {
        this.resetProcess();
      });
  }

  resetProcess() {
    this.resetRegisterForm();
    this._setWaitingStatus(false);
  }
  closePanel() {
    uikit.modal("#modal-register").hide();
  }
  activateUser() {
    const payload = { token: this.token_activation };
    this._userManagementService.userRegisterActivate(payload).then((data) => {
      this._toasterService.showToaster(data.message);
      this.token_activation = "";
    });
    this.closePanel();
    // this.location.replaceState('/map')
  }

  resetRegisterForm() {
    this.reactiveForm.reset();
    this.reactiveForm.controls.recaptchaReactive.reset();
  }

  /**
   * Returns true if all input values passed the tests.
   * Returns false if at least one didn't pass the test
   */
  checkInputValues() {
    let isPassed: boolean = true;

    const email = this.reactiveForm.get("email").value;
    const password = this.reactiveForm.get("password").value;
    const first_name = this.reactiveForm.get("firstname").value;
    const last_name = this.reactiveForm.get("lastname").value;

    if (!email.includes("@")) {
      this.errorsStr.push("The email is invalid");
      this.errors.isEmailInvalid = true;
      isPassed = false;
    }

    return isPassed;
  }

  onInputBlur(event: any) {
    switch (event.target.getAttribute("formControlName")) {
      case "email":
        this.errors.isEmailInvalid = false;
        this.errorsStr = this.errorsStr.filter(
          (item) => !item.toLowerCase().includes("email")
        );
        break;
    }
  }
}
