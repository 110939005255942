import { Injectable } from "@angular/core";
import { CalculationModuleStatusService } from "@components/calculation-module/service/calculation-module-status.service";
import { CalculationModuleService } from "@components/calculation-module/service/calculation-module.service";
import { CalculationHeatLoadDividedService } from "@components/calculation-module/service/calculation-test.service";
import { ExportDataService } from "@components/export-data/service/export-data.service";
import { DurationCurveService } from "@components/graph/duration-curve/duration-curve.service";
import { ElectricityMixService } from "@components/graph/electricity-mix/service/electricity-mix.service";
import { HeatLoadAggregateService } from "@components/graph/heat-load/heat-load.service";
import { DataInteractionService } from "@components/layers-interaction/layers-interaction.service";
import { SummaryResultService } from "@components/summary-result/summary-result.service";
import { Dictionary } from "@core/models/dictionary.class";
import { SidePanelService } from "@layouts/side-panel/side-panel.service";
import { LayersService } from "@services/layers/services/layers.service";
import { Logger } from "./logger.service";
import { SelectionScaleService } from "./selection-scale/selection-scale.service";
import { ToasterService } from "./toaster.service";

@Injectable()
export class InteractionService {
  private _summaryResultState = false;
  private _electricityGenerationResultState = false;

  private _currentCMiD = null;
  private _cmRunning = false;

  private _cmRunningProgess = 0;
  constructor(
    private _logger: Logger,
    private _sidePanelService: SidePanelService,
    private _toasterService: ToasterService,
    private _summaryResultService: SummaryResultService,
    private _layerService: LayersService,
    private _exportDataService: ExportDataService,
    private _heatLoadAggregateService: HeatLoadAggregateService,
    private _durationCurveService: DurationCurveService,
    private _dataInteractionService: DataInteractionService,
    private _electricityMixService: ElectricityMixService,
    private _calculationModuleStatusService: CalculationModuleStatusService,
    private _calculationModuleService: CalculationModuleService,
    private _calculationHeatLoadDividedService: CalculationHeatLoadDividedService,
    private _selectionScaleService: SelectionScaleService
  ) {}
  getScaleLevel() {
    return this._selectionScaleService.getScaleValue();
  }
  showToaster(msg) {
    this._toasterService.showToaster(msg);
  }

  showDangerToaster(msg) {
    this._toasterService.showDangerToaster(msg);
  }
  getLayerArray(): Dictionary {
    return this._layerService.getLayerArray();
  }
  /*     setLoadingLayerInterraction(layer) {
      this.dataInteractionService.setLoadingLayerInterraction(layer)
    }
    unsetLoadingLayerInterraction(layer) {
      this.dataInteractionService.unsetLoadingLayerInterraction(layer)

    } */
  // interface for export data service

  setSummaryResultState(val: boolean) {
    this._summaryResultState = val;
  }
  setElectricityGenerationMixResultState(val: boolean) {
    this._electricityGenerationResultState = val;
  }
  getElectricityGenerationMixResultState(): boolean {
    return this._electricityGenerationResultState;
  }
  getSummaryResultState() {
    return this._summaryResultState;
  }
  // RIGHT PANEL
  openRightPanel() {
    this._sidePanelService.openRightPanel();
  }
  closeRightPanel() {
    this._sidePanelService.closeRightPanel();
  }
  disableRightPanel() {
    this._sidePanelService.closeRightPanel();
  }

  // LEFT PANEL
  openLeftPanel() {
    this._sidePanelService.openLeftPanel();
  }
  closeLeftPanel() {
    this._sidePanelService.closeLeftPanel();
  }
  disableLeftPanel() {
    this._sidePanelService.closeLeftPanel();
  }

  getSummaryResultWithIds(payload): Promise<any> {
    return this._summaryResultService.getSummaryResultWithIds(payload);
  }
  getSummaryResultWithMultiAreas(payload: any): Promise<any> {
    return this._summaryResultService.getSummaryResultWithMultiAreas(payload);
  }
  getSummaryPersonnalLayers(payload) {
    return this._summaryResultService.getSummaryResultPersonnalLayers(payload);
  }
  getHeatLoad(payload, type_api_ref, isHectare): Promise<any> {
    return this._heatLoadAggregateService.getHeatLoad(
      payload,
      type_api_ref,
      isHectare
    );
  }
  getHeatLoadData() {
    return this._heatLoadAggregateService.getHeatLoadData();
  }
  setHeatLoadData(data) {
    return this._heatLoadAggregateService.setHeatLoadData(data);
  }
  formatHeatLoadForChartjs(data, api_ref) {
    return this._heatLoadAggregateService.formatHeatLoadForChartjs(
      data,
      api_ref
    );
  }
  getDurationCurveWithPayload(payload, isHectare): Promise<any> {
    return this._durationCurveService.getDurationCurveWithPayload(
      payload,
      isHectare
    );
  }
  transformDurationCurveData(data) {
    return this._durationCurveService.transformData(data);
  }
  getDefaultDatasetDurationCurve() {
    return this._durationCurveService.getDefaultDatasetDurationCurve();
  }
  getSplittedResults(results) {
    return this._dataInteractionService.getSplittedResults(results);
  }
  getChien(r) {
    return this._dataInteractionService.getSplittedResults(r);
  }

  getElectricityMix(payload): Promise<any> {
    return this._electricityMixService.getElectricityMix(payload);
  }

  getCMResult(summaryResult, cm): Promise<any> {
    return this._calculationHeatLoadDividedService.getCMResult(
      summaryResult,
      cm
    );
  }

  setCMResult(summaryResult, cm): Promise<any> {
    return this._calculationHeatLoadDividedService.getCMResult(
      summaryResult,
      cm
    );
  }
  getCMInformations(payload): Promise<any> {
    return this._calculationModuleService.getCMInformations(payload);
  }
  getStatusAndCMResult(id): Promise<any> {
    return this._calculationModuleService.getStatusOfCM(id);
  }
  /* getCMResultMockData() {
      return this.calculationModuleService.getCMResultMockData()

    } */
  getCMRunned() {
    return this._calculationModuleStatusService.getCmRunned();
  }
  setStatusCMPanel(value) {
    return this._calculationModuleStatusService.setStatusCMPanel(value);
  }
  setCMAnimationStatus(value) {
    this._calculationModuleStatusService.setCmAnimationStatus(value);
  }

  getCMAnimationStatus() {
    this._calculationModuleStatusService.getCmAnimationStatus();
  }
  undefinedCmRunned() {
    this._calculationModuleStatusService.undefinedCmRunned();
  }
  deleteCM(id) {
    return this._calculationModuleService.deleteCM(id);
  }

  getCmRunning() {
    return this._calculationModuleStatusService.getRunningState();
  }
  setCmRunning(cmRunning) {
    this._calculationModuleStatusService.setRunningState(cmRunning);
  }

  getCurrentIdCM() {
    return this._currentCMiD;
  }
  setCurrentIdCM(currentCMiD) {
    this._currentCMiD = currentCMiD;
  }

  getcmRunningProgess() {
    return this._cmRunningProgess;
  }
  setCmRunningProgess(cmRunningProgess) {
    this._cmRunningProgess = cmRunningProgess;
  }

  getStatusCMPanel() {
    return this._calculationModuleStatusService.getStatusCMPanel();
  }
  deleteCMTask() {
    if (this.getcmRunningProgess() > 0 && this._currentCMiD != null) {
      const currentCMiD = this._currentCMiD;
      this.setCurrentIdCM(null);
      return this._calculationModuleService
        .deleteCM(currentCMiD)
        .toPromise()
        .then((response) => {
          this._logger.log("interaction.service/deleteCMTask");
          this._currentCMiD = null;
          this.setCmRunning(false);
        })
        .catch((error) => {
          this._logger.log("deleteCMTask failed", error, "error");
        });
    }

    //WHY ?
    /*if (this.getStatusCMPanel().value == true) {
      this.setStatusCMPanel(false);
    }*/
  }
}
