import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WaitingStatusComponent } from "@core/bases/waiting-status.base";
import { ToasterService } from "@core/services/toaster.service";
import { UserManagementService } from "../service/user-management.service";

@Component({
  standalone: true,
  selector: "htm-activate",
  templateUrl: "./activate.component.html",
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class ActivateComponent extends WaitingStatusComponent {
  @Input() token_activation;
  constructor(
    private _userManagementService: UserManagementService,
    private _toasterService: ToasterService
  ) {
    super();
  }

  activateUser() {
    const payload = { token: this.token_activation };
    this._userManagementService.userRegisterActivate(payload).then((data) => {
      this._toasterService.showToaster(data.message);
      this.token_activation = "";
      window.location.href = "";
    });
  }
}
