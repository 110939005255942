import { Pipe, PipeTransform } from "@angular/core";
import { DataInteractionService } from "@components/layers-interaction/layers-interaction.service";
import { Logger } from "@core/services/logger.service";

@Pipe({
  standalone: true,
  name: "layername",
})
export class LayerNamePipe implements PipeTransform {
  constructor(
    private _dataInteractionService: DataInteractionService,
    private _logger: Logger
  ) {}

  transform(value: any, args: any[], args2): any {
    this._logger.log("LayerNamePipe/transform value", value);
    if (value === null) {
      return "Nothing to transform";
    }
    const valueTransformed =
      this._dataInteractionService.getReadableName(value);
    this._logger.log(
      "LayerNamePipe/transform valueTransformed",
      valueTransformed
    );

    return valueTransformed;
  }
}
