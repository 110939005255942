import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIService } from "@services/api.service";
import {
  apiUrl,
  postDurationCurveHectares,
  postDurationCurveNutsLau,
} from "@services/data.service";
import { LoaderService } from "@services/loader.service";
import { Logger } from "@services/logger.service";
import { ToasterService } from "@services/toaster.service";
import { DatasetChart } from "../chart/chart";

@Injectable()
export class DurationCurveService extends APIService {
  private _chartDataset: DatasetChart = {
    label: "HeatLoad",
    data: [],
    borderColor: "#2889DF",
    fill: false,
  }; // "fill: false" if the grey filling isn't good

  private _formattedValues = [];

  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService
  ) {
    super(http, logger, loaderService, toasterService);
  }

  getDurationCurveWithPayload(payload: any, isHectare): Promise<any> {
    if (isHectare === false) {
      this.logger.log(
        "DurationCurveService/getDurationCurveWithPayload",
        payload
      );
      return super.POST(payload, apiUrl + postDurationCurveNutsLau);
    } else {
      this.logger.log(
        "DurationCurveService/getDurationCurveWithPayload",
        payload
      );
      return super.POST(payload, apiUrl + postDurationCurveHectares);
    }
  }

  formatDataset() {
    this._chartDataset.data = [];
    this._formattedValues = [];
  }

  transformData(data) {
    this.formatDataset();
    for (var i = 0; i < data["points"].length; ++i) {
      this._formattedValues[i] = data["points"][i]["Y"];
    }
    this._chartDataset.data = this._formattedValues;

    return this._chartDataset;
  }
  getDefaultDatasetDurationCurve() {
    return this._chartDataset;
  }
}
