import { NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WaitingStatusComponent } from "@core/bases/waiting-status.base";
import { ToasterService } from "@services/toaster.service";
import * as uikit from "uikit";
import { UserManagementService } from "../service/user-management.service";

@Component({
  standalone: true,
  selector: "htm-recovery",
  templateUrl: "./recovery.component.html",
  imports: [NgIf, FormsModule, ReactiveFormsModule],
})
export class RecoveryComponent extends WaitingStatusComponent {
  @Input() submitedRecover = false;
  public email = "";
  public new_password = "";
  @Input() token_recover;

  constructor(
    private _toasterService: ToasterService,
    private _userManagementService: UserManagementService
  ) {
    super();
  }

  isEmailInvalid: boolean = false;
  errorStr = "";

  recoverAccountAsk() {
    this._setWaitingStatus(true);

    if (!this.checkInputValues()) {
      this._setWaitingStatus(false);
      return;
    }

    const email = this.email;
    const payload = { email: email };
    this._userManagementService
      .userRecoverAsk(payload)
      .then(() => {
        this._setWaitingStatus(false);
        this.resetRecoverComponent();
        this._toasterService.showToaster(
          "Check your email address (" + email + ") to change your password!"
        );
        uikit.modal("#modal-recover").hide();
      })
      .catch(() => {
        this._setWaitingStatus(false);
        this.submitedRecover = false;
      });
  }

  recoverAccount() {
    this._setWaitingStatus(true);
    const payload = { token: this.token_recover, password: this.new_password };
    this._userManagementService
      .userRecover(payload)
      .then((data) => {
        this.resetRecoverComponent();
        this._toasterService.showToaster(data.message);
        this._setWaitingStatus(false);
        this.submitedRecover = false;
        window.location.href = "";
      })
      .catch(() => {
        this._setWaitingStatus(false);
      });
  }
  resetRecoverComponent() {
    this.email = "";
    this.new_password = "";
    this.token_recover = "";
  }

  checkInputValues() {
    let isPassed: boolean = true;
    const email = this.email;

    if (!email.includes("@")) {
      this.errorStr = "The email is invalid";
      this.isEmailInvalid = true;
      isPassed = false;
    }

    return isPassed;
  }

  onInputBlur(event: any) {
    this.isEmailInvalid = false;
    this.errorStr = "";
  }
}
