import { NgIf } from "@angular/common";
import { Component, Input, OnDestroy } from "@angular/core";
import { Helper } from "@core/services/helper";
import { Logger } from "@core/services/logger.service";
import { ExportDataService } from "../service/export-data.service";

@Component({
  standalone: true,
  selector: "htm-export-data",
  templateUrl: "./export-data.component.html",
  styleUrls: ["./export-data.component.css"],
  imports: [NgIf],
})
export class ExportDataComponent implements OnDestroy {
  @Input() graphics;
  @Input() indicators;
  @Input() indicatorState;
  @Input() graphState;
  @Input() tabSelected;
  @Input() refSelected;

  private _displayButton = false;
  constructor(
    private _exportDataService: ExportDataService,
    private _logger: Logger,
    private _helper: Helper
  ) {}

  ngOnDestroy() {
    this._logger.log("ExportDataComponent/ngOnDestroy");
  }
  exportIndicators() {
    const arrayTmp = this._helper.summaryResultToCSV(
      this.indicators,
      this._exportDataService.dataInteractionService.inputsCM
    );
    // get name of CM session to append to file name of exported CSV
    let cmSessionName;
    try {
      cmSessionName = this.indicators.cmResult.layers[0].name;
      cmSessionName = /.*(?=\s-\sCM\s-\s.*$)/g.exec(cmSessionName);
    } catch (e) {
      cmSessionName = undefined;
    }
    this.exportData(arrayTmp, cmSessionName);
  }
  exportGraphic() {
    const arrayTmp = this._helper.chartsToCSV(this.graphics);
    this.exportData(arrayTmp);
  }
  exportData(result, cmSessionName = undefined) {
    this._exportDataService.exportData(
      result,
      this.tabSelected,
      cmSessionName,
      this.refSelected
    );
  }
}
