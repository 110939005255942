import { Directive } from "@angular/core";

@Directive()
export class WaitingStatusComponent {
  waitingStatus = false;

  protected _setWaitingStatus(value) {
    this.waitingStatus = value;
  }
}
