import { NgIf } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Logger } from "@core/services/logger.service";
import { Chart, ChartType } from "chart.js/auto";

@Component({
  standalone: true,
  selector: "htm-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.css"],
  imports: [NgIf],
})
export class ChartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("canvas", { static: true }) canvas;
  @Input() datasets;
  @Input() labels;
  @Input() type: ChartType = "line";
  @Input() title;
  @Input() options;
  @Input() isLoading = true;

  private _chart: Chart<ChartType>;

  constructor(private _logger: Logger) {}

  ngOnInit() {}
  ngOnChanges() {
    this.createChart();
  }
  ngAfterViewInit() {
    this.createChart();
  }
  createChart() {
    this.resetChart();
    this._chart = new Chart(this.canvas.nativeElement.getContext("2d"), {
      type: this.type,
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: this.options,
    });
  }
  resetChart() {
    if (this._chart) {
      this._chart.destroy();
      this._chart = void 0;
    }
  }
}
