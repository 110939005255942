<h2 class="uk-modal-title">USER ACTIVATION</h2>
<input
  class="uk-input"
  id="login-password"
  type="text"
  placeholder="Token"
  (keyup.enter)="activateUser()"
  [(ngModel)]="token_activation"
  [value]="token_activation"
  required
/>
<button class="uk-button uk-button-primary" (click)="activateUser()">
  Activate account
</button>
