<h2 class="uk-modal-title">Welcome, {{ firstname }}</h2>

<div class="uk-child-width-2-2@m uk-grid-match" uk-grid>
  <div class="account-informations">
    <h3>
      Information
      <span
        [hidden]="!isEditable"
        class="uk-margin-small-right"
        uk-icon="icon: trash"
        (click)="delete()"
      ></span>
    </h3>
    <div>
      <div class="profil-information">
        <h5 class="title">Email</h5>
        <input
          class="uk-input"
          type="text"
          [class.uk-disabled]="!isEditable"
          placeholder="Email"
          [(ngModel)]="username"
          [value]="username"
          required
        />
      </div>
      <div class="profil-information">
        <h5 class="title">Firstname</h5>
        <input
          class="uk-input"
          type="text"
          [class.uk-disabled]="!isEditable"
          placeholder="Firstname"
          [(ngModel)]="firstname"
          [value]="firstname"
          required
        />
      </div>
      <div class="profil-information">
        <h5 class="title">Lastname</h5>
        <input
          class="uk-input"
          [class.uk-disabled]="!isEditable"
          type="text"
          placeholder="Lastname"
          [(ngModel)]="lastname"
          [value]="lastname"
          required
        />
      </div>
    </div>
  </div>
</div>

<button
  *ngIf="!isEditable"
  class="uk-button uk-button-primary"
  (click)="toggleEditing()"
>
  Edit
</button>
<button
  *ngIf="!isEditable"
  class="uk-button uk-button-danger"
  (click)="logout()"
>
  Logout
</button>
<button
  *ngIf="isEditable"
  class="uk-button uk-button-primary"
  (click)="updateProfile()"
>
  Update profile
</button>
<button
  *ngIf="isEditable"
  class="uk-button uk-button-danger"
  (click)="toggleEditing()"
>
  Discard
</button>
