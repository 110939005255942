import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { Helper } from "@core/services/helper";
import { Logger } from "@core/services/logger.service";
import { ImportDataService } from "../service/import-data.service";

@Component({
  selector: "htm-import-data",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./import-data.component.html",
  styleUrls: ["./import-data.component.css"],
})
export class ImportDataComponent implements OnDestroy {
  private _displayButton = false;
  importedData: Array<any> = [];
  csvFile: File | undefined;

  extractedIndicator: string = "";
  extractedValue: string = "";
  extractedUnit: string = "";
  extractedEntryData: any[] = [];

  @Output() dataImported = new EventEmitter<any>();

  constructor(
    private _importDataService: ImportDataService,
    private _logger: Logger,
    private _helper: Helper
  ) {}

  ngOnDestroy() {
    this._logger.log("ImportDataComponent/ngOnDestroy");
  }

  handleFileInput(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      this.csvFile = files[0];
    }
  }

  importInputs() {
    if (!this.csvFile) {
      this._logger.log("No CSV file selected.");
      return;
    }

    const csvUrl = URL.createObjectURL(this.csvFile);
    this._importDataService
      .importData(csvUrl)
      .then((dataArray) => {
        this.importedData = dataArray;
        this.dataImported.emit(this.importedData);
      })
      .catch((error) => {
        this._logger.log(
          "ImportDataComponent/importIndicators/error",
          error,
          "error"
        );
      });
  }
}
