import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataInteractionCellComponent } from "@components/layers-interaction/layers-interaction-cell/layers-interaction-cell.component";
import { DataInteractionClass } from "@components/layers-interaction/layers-interaction.class";
import { DataInteractionService } from "@components/layers-interaction/layers-interaction.service";
import { UserManagementStatusService } from "@components/user-management/service/user-management-status.service";
import { InteractionService } from "@core/services/interaction.service";
import { Logger } from "@core/services/logger.service";
import { UploadService } from "@core/services/upload.service";
import { PersonnalLayersComponent } from "@layouts/layerstab/personnal-layers/personnal-layers.component";
import { ShareLayersComponent } from "@layouts/layerstab/share-layers/share-layers.component";
import { MapComponent } from "@pages/map/map.component";
import { MapService } from "@pages/map/services/map.service";
import {
  calculation_module_category,
  layers_order,
} from "@services/data.service";
import { CalculationModuleComponent } from "app/components/calculation-module/component/calculation-module.component";
import { environment } from "environments/environment";
import { control } from "leaflet";
import layers = control.layers;

@Component({
  standalone: true,
  selector: "app-layerstab",
  templateUrl: "./layerstab.component.html",
  styleUrls: ["./layerstab.component.css"],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Components
    ShareLayersComponent,
    PersonnalLayersComponent,
    CalculationModuleComponent,
    DataInteractionCellComponent,
  ],
})
export class LayerstabComponent implements OnInit {
  @Input() selectionSurface;
  @Input() layersOpened; //TODO : why empty ?
  @ViewChild(PersonnalLayersComponent, { static: false })
  personnalLayersComponent: PersonnalLayersComponent;
  @ViewChild(ShareLayersComponent, { static: false })
  shareLayersComponent: ShareLayersComponent;
  isZoneSelected = false;
  scaleLevel;
  private _nbElementsSelected = 0;
  layersSelected = [];
  public isConnected = false;
  layers: DataInteractionClass[];
  layersFiltered: DataInteractionClass[];
  category = [];
  expandedState = "collapsed";
  expanded = true;
  layerSearch: string;
  appName = environment.appName;

  constructor(
    public mapComponent: MapComponent,
    protected mapService: MapService,
    private _logger: Logger,
    private _userStatusService: UserManagementStatusService,
    private _dataInteractionService: DataInteractionService,
    private _uploadService: UploadService,
    public interactionService: InteractionService
  ) {}

  ngOnInit(): void {
    if (this.mapService.getNutsSelectedSubject()) {
      this.mapService.getLayerArray().subscribe(() => {
        this.layersSelected = this.mapService.setLayerWithoutSuffix();
        this._logger.log(
          "LeftSideComponent/this.layersSelected",
          this.layersSelected
        );
      });
    }

    this._userStatusService
      .getIsUserLogged()
      .subscribe((value) => (this.isConnected = value));
    this._dataInteractionService
      .getDataInteractionServices()
      .then((layers) => this.getLayerAndCategory(layers));
  }

  updateCmss() {
    this._dataInteractionService
      .getDataInteractionServices()
      .then((layers) => this.getLayerAndCategory(layers));
  }
  checkGroup(categoryName: string) {
    for (let c = 0; c < this.category.length; c++) {
      if (categoryName == this.category[c].name) {
        if (!this.isGroupChecked(categoryName, this.layers)) {
          this.category[c].used = 1;
          this.selectLayers(this.category[c].name, true);
        } else {
          this.category[c].used = 3;
          this.selectLayers(this.category[c].name, false);
        }
        break;
      }
    }
  }
  searchLayers(layerSearch: string) {
    if (this.layersFiltered == undefined) this.layersFiltered = this.layers;
    // 11.03.2024 : There was this line before
    //  this.layersFiltered = JSON.parse(JSON.stringify(this.layers));
    // This line generated a bug where the layers were shown, but not enabled in the layers tab
    else this.layers = this.layersFiltered;
    // 11.03.2024 :
    //  this.layers = JSON.parse(JSON.stringify(this.layersFiltered));

    this.layers = this.layers.filter(
      (layer) =>
        layer.name.toLowerCase().indexOf(layerSearch.toLowerCase()) != -1
    );
  }
  selectLayers(category: string, boolean: boolean) {
    this._logger.log(category + " to " + boolean);
    for (let layer = 0; layer < this.layers.length; layer++) {
      if (this.layers[layer].category == category) {
        if (this.interactionService.getSummaryResultState() === false) {
          this.layers[layer].isSelected = boolean;
          if (this.layers[layer].category === calculation_module_category) {
            this.mapService.displayCustomLayerFromCM(
              this.layers[layer].cm_id,
              this.layers[layer].type_of_layer
            );
          } else {
            let order = layers_order;
            if (this.layers[layer].order) {
              order = this.layers[layer].order;
            }
            this.mapService.showOrRemoveLayerWithBoolean(
              this.layers[layer].workspaceName,
              order,
              boolean
            );
            this.mapService.setLayersSubject();
          }
        }
      }
    }
    this.updateCmss();
  }

  unCheckAllLayers() {
    this.layers.forEach((layer) => {
      layer.isSelected = false;
      layer.isLoading = false;
    });
  }

  unselectAllLayers() {
    if (this.personnalLayersComponent) {
      this.personnalLayersComponent.unCheckAllLayers();
    }
    if (this.shareLayersComponent) {
      this.shareLayersComponent.unCheckAllLayers();
    }
    this.unCheckAllLayers();
    this.mapService.clearLayerSelection();
    let uploadedLayers = this.layers.filter((layer) => layer.id != 0);
    uploadedLayers.forEach((layer) => {
      this._uploadService.remove(layer.id);
    });
  }

  getLayerAndCategory(layers: any) {
    this.layers == undefined ? (this.layers = layers) : "";

    this.category = layers
      .map((item) => {
        return { name: item.category, used: 0 };
      })
      .filter(
        (value, index, self) =>
          index === self.findIndex((c) => c.name === value.name)
      );
    // this.category.push({name:calculation_module_category,used:0})
    this.layers.forEach((layer) => {
      if (layer.isSelected) {
        for (let c = 0; c < this.category.length; c++) {
          if (this.category[c].name == layer.category) {
            if (this.category[c].used == 0) {
              this.category[c].used = 1;
            }
            if (this.category[c].used == 3) {
              this.category[c].used = 2;
            }
            break;
          }
        }
      } else {
        for (let c = 0; c < this.category.length; c++) {
          if (this.category[c].name == layer.category) {
            if (this.category[c].used == 1) {
              this.category[c].used = 2;
            }
            if (this.category[c].used == 0) {
              this.category[c].used = 3;
            }
            break;
          }
        }
      }
    });
  }

  openTab(): void {
    this.layers = this.layersFiltered; // displays all the layers by default
    this.updateCmss();
    this.layersOpened = !this.layersOpened;
    if (this.layersOpened) {
      this.mapComponent.setLayersOpened();
    } else {
      this.mapComponent.setTabsClosed();
    }
  }

  closeTab(): void {
    this.layersOpened = false;
    this.mapComponent.setTabsClosed();
  }

  /**
   * Checks if all the layers of a group are selected.
   * Only the layers that are currently displayed must be selected for this function to return true
   * @param categoryName
   * @param currentLayers
   *
   * @returs true if everything is selected
   */
  isGroupChecked(
    categoryName: string,
    currentLayers: DataInteractionClass[]
  ): boolean {
    const categoryLayers = currentLayers.filter(
      (layer) => layer.category === categoryName
    );

    if (categoryLayers.length === 0) return false;

    for (const layer of categoryLayers) {
      if (layer.category !== categoryName || !layer.isSelected) return false;
    }

    return true;
  }
}
