import { Component } from "@angular/core";
import { InteractionService } from "@core/services/interaction.service";

@Component({
  template: "",
})
export class SideComponent {
  expanded = false;
  protected title = "SideComponent title";
  expandedState = "collapsed";
  constructor(protected interactionService: InteractionService) {}

  setTitle(title: string) {
    this.title = title;
  }
  getTitle(): string {
    return this.title;
  }

  toggleExpandedState() {
    this.expandedState = this.expanded ? "collapsed" : "expanded";
    this.expanded = !this.expanded;
  }
  disableButtons(functionString: string) {}

  displayPanel(val: boolean) {
    if (val) {
      this.expandedState = "expanded";
      this.expanded = true;
    } else {
      this.expandedState = "collapsed";
      this.expanded = false;
    }
  }
}
