import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIService } from "@core/services/api.service";
import { LoaderService } from "@core/services/loader.service";
import { Logger } from "@core/services/logger.service";
import { ToasterService } from "@core/services/toaster.service";

@Injectable()
export class CalculationHeatLoadDividedService extends APIService {
  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService
  ) {
    super(http, logger, loaderService, toasterService);
  }

  getCMResult(summaryResult, cm) {
    let cmResult = {};
    summaryResult.layers.map((layerResult) => {
      if (layerResult.name === "heat_tot_curr_density") {
        cmResult = {
          values: [
            {
              unit: "MWh",
              name: "heat_consumption",
              value: String(
                layerResult.values[0].value / cm.component[0].value
              ),
            },
            {
              unit: "MWh/ha",
              name: "heat_density",
              value: String(
                layerResult.values[0].value /
                  cm.component[0].value /
                  layerResult.values[2].value
              ),
            },
            {
              unit: "cells",
              name: "count_cell_heat",
              value: String(layerResult.values[2].value),
            },
          ],
          name: cm.cm.id,
        };
        // layerResult.values[0].value
      }
    });
    return Promise.resolve(cmResult);
  }
}
