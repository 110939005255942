<div id="disclaimer-header">
  <img class="disclaimer-logo" [src]="this.logoUrl" />
</div>
<div id="disclaimer-full">
  <h1>Browser unsupported</h1>
  <p>
    The toolbox is not supported on this browser. Please consider updating your
    computer with a recent browser.
  </p>
  <h2>Here is a list of recommended browsers:</h2>
  <ul>
    <li><a href="https://www.mozilla.org/firefox/new/">Firefox</a></li>
    <li><a href="https://www.google.com/chrome/">Chrome</a></li>
    <li><a href="https://www.microsoft.com/windows/microsoft-edge">Edge</a></li>
  </ul>
</div>
