import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataInteractionService } from "@components/layers-interaction/layers-interaction.service";
import { Dictionary } from "@core/models/dictionary.class";
import { BusinessInterfaceRenderService } from "@core/services/business/business.service";
import { Helper } from "@core/services/helper";
import { APIService } from "@services/api.service";
import {
  business_name_wwtp,
  constant_year,
  formatImage,
  geoserverUrl,
  populationLayerName,
  proj3035,
  unit_capacity,
  unit_heat_density,
  unit_population,
  wwtpLayerName,
} from "@services/data.service";
import { LoaderService } from "@services/loader.service";
import { Logger } from "@services/logger.service";
import { ToasterService } from "@services/toaster.service";
import { environment } from "environments/environment";
import * as L from "leaflet";
import "proj4";
import * as proj4x from "proj4";
import "proj4leaflet";
import { GeojsonClass } from "../class/geojson.class";
import { wwtp_data } from "../mock/wwtp.data";

const proj4 = (proj4x as any).default;

@Injectable()
export class LayersService extends APIService {
  private _layers = new L.FeatureGroup();
  private _zoom_layerGroup: L.LayerGroup;
  private _current_nuts_level = "0";
  /*private _heatmapOption = {
    layers: environment.prefixWorkspaceName + defaultLayer,
    format: formatImage,
    transparent: true,
    version: "1.3.0",
    zIndex: layers_order,
  };*/

  private _layersArray: Dictionary = new Dictionary([]);

  private _popup = L.popup();

  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService,
    // private interactionService: InteractionService,
    // private populationService: PopulationService,
    private _dataInteractionService: DataInteractionService,
    private _helper: Helper,
    private _businessInterfaceRenderService: BusinessInterfaceRenderService
  ) {
    super(http, logger, loaderService, toasterService);
  }

  public getLayers(): any {
    return this._layers;
  }

  getLayerArray(): Dictionary {
    return this._layersArray;
  }
  setCurrentNutsLevel(nutsLevel: string) {
    this._current_nuts_level =
      this._businessInterfaceRenderService.convertNutsToApiName(nutsLevel);
  }
  /*setupDefaultLayer() {
    const layer = this._layersArray.value(defaultLayer);
    this.logger.log(layer.toString());
    this._layers.addLayer(layer);
  }*/

  handleClickHectare(data: any) {
    this.logger.log("handleClickHectare", data);
    /* this.interactionService.setSummaryResultData(data)
    this.interactionService.openRightPanel();
    this.interactionService.enableButtonWithId('load_result') */
    // this.interactionService.enableDisplayLoader()
    this.loaderService.display(false);
  }

  getIsReadyToShowFeatureInfo(): boolean {
    let readyToShow = false;
    if (this._layersArray.keys().length > 0) {
      readyToShow = true;
    }
    return readyToShow;
  }
  addLayerWithOrder(map: any, layer: any) {
    this._layers.addLayer(<L.Layer>layer);
    this.logger.log("addLayerWithOrder layer:", layer);
    this.logger.log("addLayerWithOrder getLayers:", this._layers.getLayers());
  }

  showOrRemoveLayer(action: string, map: any, order: number) {
    if (!this._layersArray.containsKey(action)) {
      this.addLayerWithAction(action, map, order);
    } else {
      this.removelayer(action, map);
    }
    map.fireEvent("didUpdateLayers", this._layersArray);
  }
  removeLayer(action: string, map: any, order: number) {
    if (this._layersArray.containsKey(action)) {
      this.removelayer(action, map);
    }
    map.fireEvent("didUpdateLayers", this._layersArray);
  }
  removeAllLayers(map: any) {
    this._layers.clearLayers();
    this._layersArray = new Dictionary([]);
  }

  showLayer(action: string, map: any, order: number) {
    if (!this._layersArray.containsKey(action)) {
      this.addLayerWithAction(action, map, order);
    }
    map.fireEvent("didUpdateLayers", this._layersArray);
  }
  addLayerWithAction(action: string, map: any, order: number) {
    this.logger.log("layers.service/addLayerWithAction action", action);
    let layer;
    const option = {
      layers: environment.prefixWorkspaceName + action,
      format: formatImage,
      transparent: true,
      version: "1.3.0",
      srs: "EPSG:4326",
      zIndex: order,
    };
    layer = this.getTilayer(option, action);
    this._layers.addLayer(layer);
    this._layersArray.add(action, layer);
  }

  getTilayer(option: any, action): any {
    const self = this;
    const wms_request = L.tileLayer.wms(geoserverUrl, option);
    wms_request.on("load", function (data) {
      self._dataInteractionService.unsetLoadingLayerInterraction(action);
      // loader.display(false)
    });
    wms_request.on("tileunload", function () {});
    wms_request.on("tileloadstart", function (data) {
      self._dataInteractionService.setLoadingLayerInterraction(action);
    });
    wms_request.on("tileerror", function (data) {
      self._dataInteractionService.unsetLoadingLayerInterraction(action);
      self.toasterService.showToaster("Error loading tiles for " + action);
    });
    wms_request.on("loading", function () {});
    return wms_request;
  }

  removelayer(action: string, map: any) {
    this._dataInteractionService.unsetLoadingLayerInterraction(action);
    // we get the layer we want to remove
    const layer = this._layersArray.value(action);
    // we remove this layer from map
    this._layers.removeLayer(layer);
    // we destroy the layer
    this._layersArray.remove(action);
  }
  setupZoomLayerGroup(map) {
    this._zoom_layerGroup = new L.LayerGroup();
    this._zoom_layerGroup.addTo(map);
  }
  erroxFix(error) {
    this.handleError.bind(this);
    this.loaderService.display(false);
    this.toasterService.showToaster(error);
    console.error("An error occurred", error); // for demo purposes only
  }
  choosePopup(map, res: GeojsonClass, latlng: L.LatLng, action) {
    /*if (this._layersArray.containsKey(defaultLayer)) {
      this.addPopupHeatmap(map, res, latlng);
    } else*/ if (action === wwtpLayerName) {
      this.addPopupWWTP(map, res, latlng);
    } else if (action === populationLayerName + "_" + constant_year) {
      this.addPopupHectare(map, res, latlng);
    }
  }
  /* handlePopulation(map, data: any, latlng: LatLng) {
    const populationSelected = data;
    this.populationService.showPopulationSelectedLayer(populationSelected, map, latlng, this.popup);
    this.loaderService.display(false);

  } */
  /* selectAreaWithNuts(map, data: any, latlng: LatLng) {
    const populationSelected = data;
    this.populationService.showPopulationSelectedLayer(populationSelected, map, latlng, this.popup);
    this.loaderService.display(false);
  } */

  addPopupHectare(map, data: GeojsonClass, latlng: L.LatLng) {
    this.loaderService.display(false);
    const population_density = data.features[0].properties.population_density;
    this.logger.log(
      "LayersService/addPopupHectare/population_density",
      population_density
    );
    this._popup
      .setLatLng(latlng)
      .setContent(
        '<h5>Population</h5> <ul class="uk-list uk-list-divider">' +
          " <li>Population density: " +
          this._helper.round(population_density) +
          " " +
          unit_population +
          "</li> </ul>"
      )
      .openOn(map);
  }

  addPopupHeatmap(map, data: GeojsonClass, latlng: L.LatLng) {
    this.loaderService.display(false);
    const heat_density = data.features[0].properties.heat_density;
    this._popup
      .setLatLng(latlng)
      .setContent(
        '<h5>Heat map</h5> <ul class="uk-list uk-list-divider">' +
          " <li>Heat demand: " +
          this._helper.round(heat_density) +
          " " +
          unit_heat_density +
          "</li> </ul>"
      )
      .openOn(map);
  }

  addPopupWWTP(map, data: any, latlng: L.LatLng) {
    this.loaderService.display(false);
    const capacity = data.features[0].properties.capacity;
    const power = data.features[0].properties.power;
    const date = data.features[0].properties.date.split("Z")[0];
    const unit = data.features[0].properties.unit;
    this._popup
      .setLatLng(latlng)
      .setContent(
        "<h5>" +
          business_name_wwtp +
          '</h5> <ul class="uk-list uk-list-divider">' +
          "<li>Capacity: " +
          capacity +
          " " +
          unit_capacity +
          "</li><li>Power: " +
          this._helper.round(power) +
          " " +
          unit +
          "</li>" +
          "<li>Reference date: " +
          date +
          "</li></ul>"
      )
      .openOn(map);
  }
  showLayerDependingZoom(action, map, zoomLevel: number) {
    const mapZoomLevel = map.getZoom();
    this.logger.log("showLayerDependingZoom mapZoomLevel", mapZoomLevel);
    this.logger.log("showLayerDependingZoom zoomLevel", zoomLevel);
    if (this._layersArray.containsKey(action) === true) {
      if (mapZoomLevel >= zoomLevel) {
        const layer = this._layersArray.value(action);
        this._layers.removeLayer(layer);
        this.showWwtpWithMarker(map);
      } else if (mapZoomLevel < zoomLevel) {
        if (!this._layersArray.containsKey(action)) {
          this.addLayerWithAction(action, map, zoomLevel);
        } else {
          const layer = this._layersArray.value(action);
          this._layers.addLayer(layer);
        }
        this.removeWwtpWithMarker(map);
      }
    } else {
      this.removeWwtpWithMarker(map);
    }
  }

  transformLatLngToEpsg(latlng: L.LatLng, epsgString: String) {
    return proj4(epsgString).forward([latlng.lng, latlng.lat]);
  }

  getTranformedBoundingBox(map: any, epsgString): number[] {
    const coordinate = [];
    const bound = map.getBounds();
    const northEastTransformed = this.transformLatLngToEpsg(
      bound.getNorthEast(),
      epsgString
    );
    const southWestTransformed = this.transformLatLngToEpsg(
      bound.getSouthWest(),
      epsgString
    );
    coordinate.push(southWestTransformed[1], southWestTransformed[0]);
    coordinate.push(northEastTransformed[1], northEastTransformed[0]);
    return coordinate;
  }

  showWwtpWithMarker(map: any) {
    this.logger.log("showWwtpWithMarker");
    const epsg = "3035";
    const coordinate = this.getTranformedBoundingBox(map, proj3035);
    const url =
      geoserverUrl +
      "?service=wfs" +
      "&version=2.0.0" +
      "&request=GetFeature" +
      "&typeNames=hotmaps:" +
      wwtpLayerName +
      "&srsName=EPSG:" +
      epsg +
      "&bbox=" +
      coordinate.toString() +
      "&outputFormat=application/json";
    this.logger.log("showWwtpWithMarker coordinate", coordinate);
    this.logger.log("showWwtpWithMarker url", url);
    return Promise.resolve(wwtp_data).then((res) => {
      this.addPOIToMapRegular(res, map);
    });
    /* this.http.get(url).map((data: Response) => data.json() as any)
        .subscribe(res => this.addPOIToMap(res, map), err => this.handleError.bind(this)); */
    /* Promise.resolve(wwtp_data).then((data) => { */
  }
  addPOIToMap(data, map) {
    data.features.forEach((element) => {
      const point = element.geometry.coordinates;
      const pointProj = proj4(proj3035).inverse([point[0], point[1]]);
      const marker = L.marker(L.latLng(pointProj[1], pointProj[0]), {
        icon: L.icon({
          iconUrl: "../../assets/leaflet-images/marker-icon.png",
          iconSize: [28, 40],
          iconAnchor: [14, 20],
        }),
      });
      this._zoom_layerGroup.addLayer(marker);
    });
    this._zoom_layerGroup.addTo(map);
  }

  addPOIToMapRegular(data, map) {
    proj4.defs("urn:ogc:def:crs:EPSG::3035", proj3035);
    // this.zoom_layerGroup =  L.Proj.geoJson(data)

    function onEachFeature(feature, layer) {
      if (feature.properties) {
        layer.setIcon(
          L.icon({
            iconUrl: "../../assets/leaflet-images/marker-icon.png",
            iconSize: [28, 40],
            iconAnchor: [14, 20],
          })
        );
      }
    }
    this._zoom_layerGroup = L.Proj.geoJson(data, {
      onEachFeature: onEachFeature,
    }).addTo(map);

    this._zoom_layerGroup.addTo(map);
  }

  removeWwtpWithMarker(map: any) {
    this.logger.log("removeWwtpWithMarker");
    this._zoom_layerGroup.removeFrom(map);
  }
}
