import { NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WaitingStatusComponent } from "@core/bases/waiting-status.base";
import { ToasterService } from "@services/toaster.service";
import { UserManagementStatusService } from "../service/user-management-status.service";
import { UserManagementService } from "../service/user-management.service";

@Component({
  standalone: true,
  selector: "htm-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  imports: [NgIf, FormsModule, ReactiveFormsModule],
})
export class LoginComponent extends WaitingStatusComponent {
  username = "";
  password = "";
  errorStr = "";
  private _token = "";
  constructor(
    private _userManagementService: UserManagementService,
    private _userManagementStatusService: UserManagementStatusService,
    private _toasterService: ToasterService
  ) {
    super();
  }

  connect() {
    this._setWaitingStatus(true);
    const payload = { password: this.password, email: this.username };
    this._userManagementService
      .userLogin(payload)
      .then((data) => {
        this._token = data.token;
        this._toasterService.showToaster(data.message);
        this.setUserIsLoggedIn();
      })
      .catch((e) => {
        this._setWaitingStatus(false);
        this._userManagementStatusService.setUserIsLoggedOut();
        this.errorStr = "The email or password is incorrect, please try again.";
        this._toasterService.showDangerToaster(this.errorStr);
      });
  }

  setUserIsLoggedIn() {
    this._userManagementStatusService.setUserToken(this._token);
    this._userManagementStatusService.setUserIsLoggedIn();
    this._userManagementStatusService.setUsername(this.username);

    this._setWaitingStatus(false);
  }
}
