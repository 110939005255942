/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 27.05.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocationClass } from "@core/models/location/location.class";
import {
  geocodeUrl,
  getIpUrl,
  getLocationFromIp,
} from "@services/data.service";
import * as L from "leaflet";
import { map, mergeMap } from "rxjs/operators";
import { LoaderService } from "./loader.service";
import { Logger } from "./logger.service";

@Injectable()
export class GeocodingService {
  http: HttpClient;
  constructor(
    http: HttpClient,
    private _logger: Logger,
    private _loaderService: LoaderService
  ) {
    this.http = http;
    this._logger = _logger;
  }

  geocode(address: string) {
    this._logger.log("GeocodingService/geocode");
    this._loaderService.display(true);
    return this.http
      .get(
        geocodeUrl +
          encodeURIComponent(address) +
          "&format=json&polygon=1&addressdetails=1"
      )
      .pipe(
        map((res) => res),
        map((result) => {
          //if (result.status !== 'OK') { throw new Error('unable to geocode address'); }

          const location = new LocationClass();
          location.address = result[0].display_name;
          location.latitude = result[0].lat;

          location.longitude = result[0].lon;

          const viewPort = result[0].boundingbox;
          location.viewBounds = L.latLngBounds(
            {
              lat: viewPort[0],
              lng: viewPort[2],
            },
            {
              lat: viewPort[1],
              lng: viewPort[3],
            }
          );
          this._loaderService.display(false);
          return location;
        })
      );
  }

  getCurrentLocation() {
    this._logger.log("GeocodingService/getCurrentLocation");
    // this.loaderService.display(true);
    return this.http.get(getIpUrl).pipe(
      map((res) => res["ip"]),
      mergeMap((ip) => this.http.get(getLocationFromIp + ip)),
      map((res) => res),
      map((result) => {
        const location = new LocationClass();

        location.address =
          result["city"] +
          ", " +
          result["region_code"] +
          " " +
          result["zip_code"] +
          ", " +
          result["country_code"];
        location.latitude = result["latitude"];
        location.longitude = result["longitude"];
        //   this.loaderService.display(false);
        return location;
      })
    );
  }

  private handleError(error: any): Promise<any> {
    this._logger.log("GeocodingService/handleError error", error, "error");
    this._loaderService.display(false);
    return Promise.reject(error.message || error);
  }
}
