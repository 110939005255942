import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { CookiesService } from "@core/services/cookies.service";
import { MapService } from "@pages/map/services/map.service";
import { enableFeedback } from "@services/data.service";
import { environment } from "environments/environment";
import * as uikit from "uikit";

@Component({
  standalone: true,
  selector: "htm-welcome-page",
  templateUrl: "./welcome-page.component.html",
  styleUrls: ["./welcome-page.component.css"],
  imports: [CommonModule],
})
export class WelcomePageComponent implements OnInit {
  enableFeedback = enableFeedback;
  appName = environment.appName;
  logoUrl = environment.logoUrl;
  partnersLogosUrl = environment.partnersLogosUrl;
  introText = environment.introText;
  websiteUrl = environment.websiteUrl;
  wikiUrl = environment.wikiUrl;
  repositoryUrl = environment.repositoryUrl;
  dataSetURl = environment.dataSetURl;
  isDevOrLocalhost = "";

  constructor(
    public cookies: CookiesService,
    private _mapService: MapService
  ) {}

  ngOnInit() {
    uikit.modal("#modal-full").show();

    this.isDevOrLocalhost = this._mapService.setIsDevOrLocalHost();
  }
}
