<div
  [ngClass]="mapComponent.openedTabs[1] ? 'sidetab sidetabSelected' : 'sidetab'"
  (click)="openTab()"
>
  Layers
</div>
<div
  *ngIf="layersOpened"
  id="layertab"
  [ngClass]="
    mapComponent.openedTabs[1] ? 'layertabWrapper tabopen' : 'layertabWrapper'
  "
>
  <div class="header">
    <h3>Layers</h3>
    <p (click)="closeTab()">x</p>
  </div>

  <div class="layer-list-container">
    <input
      type="text"
      class="uk-input search"
      placeholder="Search Layers..."
      [ngModel]="layerSearch"
      (ngModelChange)="searchLayers($event)"
    />
    <button
      class="uk-button uk-button-primary uk-button-small unselectButton"
      (click)="unselectAllLayers()"
    >
      Unselect all layers
    </button>
    <ul class="layer-list">
      <li>
        <span>
          <htm-personnal-layers *ngIf="isConnected"></htm-personnal-layers>
          <htm-share-layers *ngIf="isConnected"></htm-share-layers>
          <!-- Use a unique ID for each accordion -->
          <!-- Fixes accordion not closing and opening -->
          <ng-container *ngFor="let i of category; let index = index">
            <div *ngIf="i.name != ''">
              <ul [attr.id]="'accordion-' + index" uk-accordion>
                <li class="uk-open">
                  <h3 class="uk-accordion-title">
                    <div (click)="checkGroup(i.name)" class="checkbox">
                      <div
                        [ngClass]="
                          isGroupChecked(i.name, layers)
                            ? 'checked'
                            : 'unchecked'
                        "
                      ></div>
                    </div>
                    {{ i.name }}
                  </h3>
                  <div class="uk-accordion-content">
                    <ng-container *ngFor="let n of layers">
                      <!--TODO: Fix heat2 hard coded filter-->
                      <span
                        class="layerslist"
                        *ngIf="
                          i.name === n.category &&
                          n.category != '' &&
                          n.layer_type != 'heat2'
                        "
                      >
                        <layers-interaction-cell
                          [dataInteraction]="n"
                          [expanded]="expanded"
                          disabled="true"
                        ></layers-interaction-cell>
                      </span>
                    </ng-container>
                  </div>
                </li>
              </ul>
            </div>
          </ng-container>
        </span>
      </li>
      <li [hidden]="!isZoneSelected">
        <htm-cms
          [expandedState]="expandedState"
          [expanded]="expanded"
          [layersSelected]="layersSelected"
          [scaleLevel]="scaleLevel"
          [selectionSurface]="selectionSurface"
        ></htm-cms>
      </li>
    </ul>
  </div>
</div>
