/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 13.07.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/

import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { LoaderService } from "./loader.service";
import { ToasterService } from "./toaster.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private _injector: Injector) {}

  handleError(error) {
    const loaderService = this._injector.get(LoaderService);
    loaderService.display(false);
    const message = error.message ? error.message : error.toString();
    const toasterService = this._injector.get(ToasterService);
    toasterService.showToaster(message);

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }
}
