import { NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataInteractionService } from "@components/layers-interaction/layers-interaction.service";
import { WaitingStatusComponent } from "@core/bases/waiting-status.base";
import { InteractionService } from "@core/services/interaction.service";
import {
  color_unusedspace,
  color_usedspace,
  diskspacechart_options,
  labels_diskspacechart,
} from "@services/data.service";
import { ToasterService } from "@services/toaster.service";
import { UserManagementStatusService } from "../service/user-management-status.service";
import { UserManagementService } from "../service/user-management.service";

@Component({
  standalone: true,
  selector: "htm-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.css"],
  imports: [NgIf, FormsModule, ReactiveFormsModule],
})
export class AccountComponent extends WaitingStatusComponent implements OnInit {
  @Input() username;
  @Input() token;
  firstname = "";
  lastname = "";
  isEditable = false;
  private _diskspaceUsed;
  private _diskspaceMax;
  diskspaceLabels = labels_diskspacechart;
  diskspaceDataset;
  diskspaceOptions = diskspacechart_options;
  constructor(
    private _userManagementService: UserManagementService,
    private _userManagementStatusService: UserManagementStatusService,
    private _toasterService: ToasterService,
    private _interactionService: InteractionService,
    private _dataInteractionService: DataInteractionService
  ) {
    super();
  }

  ngOnInit() {
    this.getAcountInformation();
  }

  logout() {
    this._setWaitingStatus(true);
    this._userManagementService
      .userLogout(this.token)
      .then((res) => this.setUserIsLoggedOut(res.message))
      .catch(() => this._setWaitingStatus(false));

    this._dataInteractionService.removeAllUserLayers();
  }

  delete(): void {
    if (
      confirm(
        "Are sure you want to delete your account?\n" +
          "     All your data will be lost!"
      )
    ) {
      this.waitingStatus = true;
      this._userManagementService
        .userDelete(this.token)
        .then((res) => {
          this._toasterService.showToaster(
            "Your account and data have been successfully deleted"
          );
          this.setUserIsLoggedOut(res.message);
        })
        .catch(() => {
          this._toasterService.showToaster("The deletion failed");
          this._setWaitingStatus(false);
        });
    }
  }

  private setUserIsLoggedOut(msg?: string): void {
    this._userManagementStatusService.setUsername(null);
    this._userManagementStatusService.setUserIsLoggedOut();
    this._userManagementStatusService.setUserToken(null);

    if (msg) this._toasterService.showToaster(msg);
  }

  getAcountInformation() {
    this.getUserInformations();
    if (this._userManagementService.getDiskSpace)
      this._userManagementService.getDiskSpace(this.token).then((data) => {
        this._diskspaceMax = data.max_size;
        this._diskspaceUsed = data.used_size;
        this.diskspaceDataset = [
          {
            label: "Account disk",
            backgroundColor: [color_usedspace, color_unusedspace],
            data: [
              this._diskspaceUsed,
              this._diskspaceMax - this._diskspaceUsed,
            ],
          },
        ];
      });
  }
  getAcountFileList() {
    this._userManagementService
      .getAcountFileList(this.token)
      .then((data) => {});
  }
  toggleEditing() {
    this.isEditable = !this.isEditable;
  }
  disableEditing() {
    this.getUserInformations();
    this.toggleEditing();
  }
  getUserInformations() {
    if (this._userManagementService.getUserInformations)
      this._userManagementService
        .getUserInformations(this.token)
        .then((data) => {
          this.firstname = data.first_name;
          this.lastname = data.last_name;
        })
        .catch(() => {
          this.setUserIsLoggedOut();
        });
  }
  updateProfile() {
    const payload = {
      token: this.token,
      last_name: this.lastname,
      first_name: this.firstname,
    };
    this._userManagementService.userUpdateProfile(payload).then((data) => {
      this._toasterService.showToaster(data.message);
      this.getUserInformations();
      this.toggleEditing();
    });
  }
}
