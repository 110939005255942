<div
  [class.isLoggedIn]="isLoggedIn"
  [class.isLoggedOut]="!isLoggedIn"
  class="userWrapper"
>
  <a href="#modal-login" *ngIf="!isLoggedIn" class="uk-icon-link" uk-toggle
    ><button uk-icon="user" onclick="_paq.push(['trackEvent', 'Login', 'Login Button Click', 'user try to login']);"></button
  ></a>
  <a href="#modal-account" *ngIf="isLoggedIn" class="uk-icon-link" uk-toggle
    ><span class="link-right">{{ username.substring(0, 2) }}</span></a
  >
</div>

<div id="modal-login" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div *ngIf="this.cookies.cookiesAccepted(); else acceptCookies">
      <htm-login *ngIf="!isLoggedIn"></htm-login>
    </div>
    <ng-template #acceptCookies>
      <htm-accept-cookies />
    </ng-template>
    <div></div>
  </div>
</div>
<div id="modal-register" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <htm-register [token_activation]="token_activation"></htm-register>
  </div>
</div>
<div id="modal-recover" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <htm-recovery
      [token_recover]="token_recover"
      [submitedRecover]="submitedRecover"
    ></htm-recovery>
  </div>
</div>
<div id="modal-account" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <htm-account
      *ngIf="isLoggedIn"
      [username]="username"
      [token]="token"
    ></htm-account>
  </div>
</div>
<div id="modal-activate" uk-modal>
  <div *ngIf="token_activation" class="uk-modal-dialog uk-modal-body">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <htm-activate [token_activation]="token_activation"></htm-activate>
  </div>
</div>

<div id="modal-gdpr" uk-modal="stack: true;">
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div class="uk-modal-body">
      <htm-gdpr></htm-gdpr>
    </div>
  </div>
</div>
