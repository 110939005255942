<ul uk-accordion *ngIf="standaloneCMs.length > 0">
  <li class="uk-open">
    <h3 class="uk-accordion-title">STAND-ALONE CMs</h3>
    <div class="uk-accordion-content">
      <ng-container *ngFor="let cm of standaloneCMs">
        <div class="uk-container">
          <div class="uk-offcanvas-content">
            <button
              class="uk-button uk-button-default uk-margin-small-right"
              style="cursor: auto"
            >
              <span onclick="_paq.push(['trackEvent', 'CM', 'Calculation module Click', this.innerText]);" class="cm-name">{{ cm.name }}</span>
              <div class="cm-toolbox">
                <a
                  *ngIf="cm.wiki_url"
                  href="{{ cm.wiki_url }}"
                  target="_blank"
                  uk-tooltip="title:Wiki URL;pos: bottom"
                >
                  <div
                    src="../../../../assets/first-page/logo_wiki.svg"
                    class="icon-wiki"
                  ></div>
                </a>
                <a
                  *ngIf="cm.repository_url"
                  href="{{ cm.repository_url }}"
                  target="_blank"
                  class="uk-icon-link git-link"
                  uk-icon="icon:github; ratio: 1.5"
                  uk-tooltip="title:Repository URL;pos: bottom"
                ></a>
              </div>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </li>
</ul>
