<div
  [@feedbackTrigger]="expandedState"
  *ngIf="expandedState"
  class="formWrapper"
>
  <div class="feedback-content">
    <ng-container *ngIf="this._cookies.cookiesAccepted() == true">
      <!-- Feedback form -->
      <form
        *ngIf="!submited"
        #form="ngForm"
        (ngSubmit)="submit(form)"
        enctype="multipart/form-data"
        method="post"
        novalidate
      >
        <div class="header">
          <h4>Feedback</h4>
          <a (click)="close($event)" uk-close></a>
        </div>
        <fieldset class="uk-fieldset">
          <div class="uk-margin-small">
            <input
              name="name"
              class="uk-input uk-form-small"
              type="text"
              placeholder="Firstname / Lastname"
              ngModel
              #names="ngModel"
              required
              autocomplete="off"
            />
          </div>
          <div class="uk-margin-small">
            <input
              name="mail"
              class="uk-input uk-form-small"
              type="text"
              placeholder="Mail"
              required
              email
              ngModel
              #mail="ngModel"
            />
          </div>
          <div class="uk-margin-small">
            <input
              name="company"
              class="uk-input uk-form-small"
              type="text"
              placeholder="Company"
              required
              company
              ngModel
              #company="ngModel"
              autocomplete="off"
            />
          </div>
          <!-- <div class='uk-margin-small uk-grid-small uk-child-width-auto uk-grid'>
                <label><input class='uk-radio' type='radio' name='categorie' value='Issue' ngModel #categorie='ngModel' required> Issue</label>
                <label><input class='uk-radio' type='radio' name='categorie' value='Improvement' ngModel #categorie='ngModel'> Improvement</label>
            </div> -->
          <div class="uk-margin-small">
            <select
              class="uk-select uk-form-small"
              name="type"
              [(ngModel)]="type"
              required
            >
              <option [ngValue]="null">Select feedback type...</option>
              <option
                *ngFor="let issue_type of issue_type"
                [ngValue]="issue_type"
              >
                {{ issue_type.name }}
              </option>
            </select>
          </div>
          <div class="uk-margin-small">
            <select
              class="uk-select uk-form-small"
              name="level"
              [(ngModel)]="level"
              required
            >
              <option [ngValue]="null">Select feedback level...</option>
              <option
                *ngFor="let issue_level of issue_levels"
                [ngValue]="issue_level"
              >
                {{ issue_level.name }}
              </option>
            </select>
          </div>
          <div class="uk-margin-small">
            <input
              name="title"
              class="uk-input uk-form-small"
              type="text"
              placeholder="Title"
              ngModel
              #title="ngModel"
              required
            />
          </div>
          <div class="uk-margin-small">
            <textarea
              name="description"
              class="uk-textarea uk-form-small"
              rows="3"
              placeholder="Description"
              ngModel
              #description="ngModel"
              required
            ></textarea>
          </div>
          <div class="uk-margin-small">
            <div class="uk-width-1-1 uk-inline" uk-form-custom="target: true">
              <input
                type="file"
                name="picture"
                #file_input
                (change)="onUploadFile(file_input.files)"
                accept="image/*"
              />
              <input
                #file_string
                id="personal-upload-field"
                class="uk-input input-file uk-form-small"
                type="text"
                placeholder="Select / drag&drop file"
                disabled
              />
              <span class="uk-form-icon" uk-icon="icon: upload"></span>
            </div>
          </div>
          <div class="uk-margin-small">
            <re-captcha
              siteKey="6Ldb7zcUAAAAADpGmUYGHKDq4HnQ-WqzfqissGje"
              name="captcha"
              [(ngModel)]="captcha"
              required
            ></re-captcha>
          </div>
          <div class="uk-margin-small">
            <button
              class="uk-button uk-button-default uk-width-1-1"
              type="submit"
              [disabled]="!form.valid"
            >
              Submit
            </button>
          </div>
        </fieldset>
      </form>
    </ng-container>
    <ng-container *ngIf="this._cookies.cookiesAccepted() == false">
      <a class="close-button" (click)="close($event)" uk-close></a>
      <htm-accept-cookies />
    </ng-container>

    <!-- Loading spinner -->
    <ng-container *ngIf="feedbackLoader">
      <div class="loader-container">
        <div class="loader" uk-spinner></div>
      </div>
    </ng-container>

    <!-- Message after submitting Feedback -->
    <ng-container *ngIf="submited">
      <form
        *ngIf="submited"
        #form="ngForm"
        (ngSubmit)="makeNewRequest(form)"
        enctype="multipart/form-data"
        method="post"
        novalidate
      >
        <div class="header">
          <h4>Feedback</h4>
          <a (click)="close($event)" uk-close></a>
        </div>
        <fieldset class="uk-fieldset">
          <div class="uk-margin-small">
            <div
              [innerHTML]="submitMessage.msg"
              [ngClass]="{ 'uk-text-danger': !submitMessage.success }"
            ></div>
            <button
              class="uk-button uk-button-default uk-width-1-1"
              (click)="makeNewRequest()"
            >
              New request
            </button>
          </div>
        </fieldset>
      </form>
    </ng-container>
  </div>
</div>
