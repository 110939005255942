import { Component, OnInit } from "@angular/core";

import { DatePipe, NgForOf, NgIf } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserManagementStatusService } from "@components/user-management/service/user-management-status.service";
import { InteractionService } from "@services/interaction.service";
import { LoaderService } from "@services/loader.service";
import { SnapshotConfig, SnapshotService } from "@services/snapshot.service";

@Component({
  standalone: true,
  selector: "htm-snapshot-panel",
  templateUrl: "./snapshot.component.html",
  styleUrls: ["./snapshot.component.less"],
  imports: [
    NgIf,
    NgForOf,
    FormsModule,
    ReactiveFormsModule,

    // Pipes
    DatePipe,
  ],
})
export class SnapshotComponent implements OnInit {
  snapshots: SnapshotConfig[] = [];
  newSnapshot: string;
  newOpen = false;
  isLogged: boolean;

  constructor(
    private _snapshotService: SnapshotService,
    private _interactionService: InteractionService,
    private _loaderService: LoaderService,
    private _userManagementStatusService: UserManagementStatusService
  ) {
    this._userManagementStatusService
      .getIsUserLogged()
      .subscribe((isLogged) => {
        this.isLogged = isLogged;
        this.refresh();
        if (!isLogged) {
          this.snapshots = [];
        }
      });
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    if (
      this._snapshotService.list &&
      this._userManagementStatusService.getIsUserLogged().value
    )
      this._snapshotService
        .list()
        .then((snapshots) => (this.snapshots = snapshots));
  }

  apply(snapshot: SnapshotConfig) {
    this._loaderService.display(true);
    this._snapshotService.apply(snapshot, () => {
      this._loaderService.display(false);
    });
  }

  delete(snapshot: SnapshotConfig) {
    this._snapshotService.delete(snapshot).then(() => this.refresh());
  }

  openSave() {
    this.newOpen = true;
  }

  closeSave() {
    this.newOpen = false;
  }

  save() {
    this._snapshotService.add(this.newSnapshot, "").then((success) => {
      if (success) {
        this.newSnapshot = "";
        this.refresh();
      }
    });
  }
}
