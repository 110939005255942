import { BusinessInterfaceRenderClass } from "./business.class";

const heat_tot_curr_density = "heat_tot_curr_density";
const gfa_tot_curr_density = "gfa_tot_curr_density";
const gfa_res_curr_density = "gfa_res_curr_density";
const gfa_nonres_curr_density = "gfa_nonres_curr_density";
const ghs_built_1975_100_share = "ghs_built_1975_100_share";
const ghs_built_1990_100_share = "ghs_built_1990_100_share";
const ghs_built_2000_100_share = "ghs_built_2000_100_share";
const ghs_built_2014_100_share = "ghs_built_2014_100_share";
const potential_solarthermal_collectors_rooftop =
  "potential_solarthermal_collectors_rooftop";
const potential_solarthermal_collectors_open_field =
  "potential_solarthermal_collectors_open_field";
const heat_res_curr_density = "heat_res_curr_density";
const heat_nonres_curr_density = "heat_nonres_curr_density";
const solar_optimal_total = "solar_optimal_total";
const vol_tot_curr_density = "vol_tot_curr_density";
const vol_res_curr_density = "vol_res_curr_density";
const cool_tot_curr_density = "cool_tot_curr_density";
const vol_nonres_curr_density = "vol_nonres_curr_density";
const pop_tot_curr_density = "pop_tot_curr_density";
const wwtp_capacity = "wwtp_capacity";
const wwtp_power = "wwtp_power";

const potential_forest = "potential_forest";
const livestock_effluents_view = "livestock_effluents_view";
const solid_waste = "solid_waste_view";
const output_wind_speed = "output_wind_speed";
const wind_50m = "wind_50m";
const potential_potential_shallowgeothermal = "shallow_geothermal_potential";
const yearly_co2_emission = "yearly_co2_emission";
const hdd_curr = "hdd_curr";
const cdd_curr = "cdd_curr";
const land_surface_temperature = "land_surface_temperature";

const motorization_rate = "motorization_rate_nuts2_view";
const default_ev_density = "default_ev_density";
const cs_density = "cs_density";
const rasterized_iso_urban = "rasterized_iso_urban";
const rasterized_iso_rural = "rasterized_iso_rural";
const rasterized_border = "rasterized_border";
const rasterized_urau = "rasterized_urau";
const rasterized_urau_rural = "rasterized_urau_rural";
const work_osm = "work_osm";
const filtered_corine_land_cover = "filtered_corine_land_cover";
const poi_osm = "poi_osm";

const agricultural_residues_view = "agricultural_residues_view";
const solar_radiation = "solar_radiation";

export const BusinessInterfaceRenderArray: BusinessInterfaceRenderClass[] = [
  // Buildings
  {
    api_name: heat_tot_curr_density + "_consumption",
    business_name: "Heat demand total",
  },
  {
    api_name: heat_tot_curr_density + "_count_cell",
    business_name: "Counted Cells",
  },
  {
    api_name: heat_tot_curr_density + "_consumption_min",
    business_name: "Heat density min",
  },
  {
    api_name: heat_tot_curr_density + "_consumption_max",
    business_name: "Heat density max",
  },
  {
    api_name: heat_tot_curr_density + "_density",
    business_name: "Average heat density",
  },
  {
    api_name:
      heat_tot_curr_density +
      "_" +
      heat_tot_curr_density +
      "_per_" +
      pop_tot_curr_density,
    business_name: "Heat demand per person",
  },

  {
    api_name: heat_res_curr_density + "_consumption",
    business_name: "Heat demand total",
  },
  {
    api_name: heat_res_curr_density + "_count_cell",
    business_name: "Counted Cells",
  },
  {
    api_name: heat_res_curr_density + "_density",
    business_name: "Average heat density",
  },
  {
    api_name:
      heat_res_curr_density +
      "_" +
      heat_res_curr_density +
      "_per_" +
      pop_tot_curr_density,
    business_name: "Heat demand per person",
  },

  {
    api_name: heat_nonres_curr_density + "_consumption",
    business_name: "Heat demand total",
  },
  {
    api_name: heat_nonres_curr_density + "_count_cell",
    business_name: "Counted Cells",
  },
  {
    api_name: heat_nonres_curr_density + "_density",
    business_name: "Average heat density",
  },
  {
    api_name:
      heat_nonres_curr_density +
      "_" +
      heat_nonres_curr_density +
      "_per_" +
      pop_tot_curr_density,
    business_name: "Heat demand per person",
  },

  {
    api_name: cool_tot_curr_density + "_consumption",
    business_name: "Theoretical cooling needs total",
  },
  {
    api_name: cool_tot_curr_density + "_count_cell",
    business_name: "Counted Cells",
  },
  {
    api_name: cool_tot_curr_density + "_consumption_min",
    business_name: "Cooling density min",
  },
  {
    api_name: cool_tot_curr_density + "_consumption_max",
    business_name: "Cooling density max",
  },
  {
    api_name: cool_tot_curr_density + "_density",
    business_name: "Average cooling density",
  },
  {
    api_name:
      cool_tot_curr_density +
      "_" +
      cool_tot_curr_density +
      "_per_" +
      pop_tot_curr_density,
    business_name: "Theoretical cooling needs per person",
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Gross floor Area
  {
    api_name: gfa_tot_curr_density + "_total",
    business_name: "Gross floor area",
  },
  {
    api_name: gfa_tot_curr_density + "_density",
    business_name: "Gross floor area density",
  },
  {
    api_name: gfa_tot_curr_density + "_count_cell",
    business_name: "Counted cells",
  },
  {
    api_name:
      gfa_tot_curr_density +
      "_" +
      gfa_tot_curr_density +
      "_per_" +
      pop_tot_curr_density,
    business_name: "GFA per person",
  },

  {
    api_name: gfa_res_curr_density + "_total",
    business_name: "Gross floor area",
  },
  {
    api_name: gfa_res_curr_density + "_density",
    business_name: "Gross floor area density",
  },
  {
    api_name: gfa_res_curr_density + "_count_cell",
    business_name: "Counted cells",
  },
  {
    api_name:
      gfa_res_curr_density +
      "_" +
      gfa_res_curr_density +
      "_per_" +
      pop_tot_curr_density,
    business_name: "GFA per person",
  },

  {
    api_name: gfa_nonres_curr_density + "_total",
    business_name: "Gross floor area",
  },
  {
    api_name: gfa_nonres_curr_density + "_density",
    business_name: "Gross floor area density",
  },
  {
    api_name: gfa_nonres_curr_density + "_count_cell",
    business_name: "Counted cells",
  },
  {
    api_name:
      gfa_nonres_curr_density +
      "_" +
      gfa_nonres_curr_density +
      "_per_" +
      pop_tot_curr_density,
    business_name: "GFA per person",
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Construction periods
  {
    api_name: ghs_built_1975_100_share + "_density",
    business_name: "Average share of GFA in the construction period",
  },
  {
    api_name: ghs_built_1975_100_share + "_count_cell",
    business_name: "Counted cells",
  },

  {
    api_name: ghs_built_1990_100_share + "_density",
    business_name: "Average share of GFA in the construction period",
  },
  {
    api_name: ghs_built_1990_100_share + "_count_cell",
    business_name: "Counted cells",
  },

  {
    api_name: ghs_built_2000_100_share + "_density",
    business_name: "Average share of GFA in the construction period",
  },
  {
    api_name: ghs_built_2000_100_share + "_count_cell",
    business_name: "Counted cells",
  },

  {
    api_name: ghs_built_2014_100_share + "_density",
    business_name: "Average share of GFA in the construction period",
  },
  {
    api_name: ghs_built_2014_100_share + "_count_cell",
    business_name: "Counted cells",
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Building volume
  {
    api_name: vol_tot_curr_density + "_total",
    business_name: "Building volume",
  },
  {
    api_name: vol_tot_curr_density + "_density",
    business_name: "Building volume density",
  },
  {
    api_name: vol_tot_curr_density + "_count_cell",
    business_name: "Counted cells",
  },
  {
    api_name:
      vol_tot_curr_density +
      "_" +
      vol_tot_curr_density +
      "_per_" +
      pop_tot_curr_density,
    business_name: "Building volume per person",
  },

  {
    api_name: vol_res_curr_density + "_total",
    business_name: "Building volume",
  },
  {
    api_name: vol_res_curr_density + "_density",
    business_name: "Building volume density",
  },
  {
    api_name: vol_res_curr_density + "_count_cell",
    business_name: "Counted cells",
  },
  {
    api_name:
      vol_res_curr_density +
      "_" +
      vol_res_curr_density +
      "_per_" +
      pop_tot_curr_density,
    business_name: "Building volume per person",
  },

  {
    api_name: vol_nonres_curr_density + "_total",
    business_name: "Building volume",
  },
  {
    api_name: vol_nonres_curr_density + "_density",
    business_name: "Building volume density",
  },
  {
    api_name: vol_nonres_curr_density + "_count_cell",
    business_name: "Counted cells",
  },
  {
    api_name:
      vol_nonres_curr_density +
      "_" +
      vol_nonres_curr_density +
      "_per_" +
      pop_tot_curr_density,
    business_name: "Building volume per person",
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Industry
  {
    api_name: "industrial_database_emissions_value",
    business_name: "CO₂ emissions of energy intensive industries",
  },
  {
    api_name: "industrial_database_excess_heat_value0",
    business_name: "excess heat potential [0°C - 50°C]",
  },
  {
    api_name: "industrial_database_excess_heat_value4",
    business_name: "excess heat potential [50°C - 100°C]",
  },
  {
    api_name: "industrial_database_excess_heat_value1",
    business_name: "excess heat potential [100°C - 200°C]",
  },
  {
    api_name: "industrial_database_excess_heat_value2",
    business_name: "excess heat potential [200°C - 500°C]",
  },
  {
    api_name: "industrial_database_excess_heat_value3",
    business_name: "excess heat potential [ > 500°C]",
  },
  {
    api_name: "industrial_database_excess_heat_value5",
    business_name: "ventilation",
  },
  {
    api_name: "industrial_database_excess_heat_value6",
    business_name: "business process",
  },
  {
    api_name: "industrial_database_excess_heat_value7",
    business_name: "lighting",
  },
  {
    api_name: "industrial_database_excess_heat_value8",
    business_name: "total",
  },
  {
    api_name: "industrial_database_excess_heat_total",
    business_name: "Total excess heat potential of energy-intensive industries",
  },

  {
    api_name: "industrial_database_emissions_NominalPower",
    business_name: "Nominal power",
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Population
  {
    api_name: pop_tot_curr_density + "_population",
    business_name: "Total population",
  },
  {
    api_name: pop_tot_curr_density + "_density",
    business_name: "Average population density",
  },
  {
    api_name: pop_tot_curr_density + "_count_cell",
    business_name: "Counted cells",
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Mobility

  // motorization rate
  {
    api_name: motorization_rate + "_value",
    business_name: "Total motorization rate",
  },
  // ev density
  {
    api_name: default_ev_density + "_Total",
    business_name: "Total density",
  },
  {
    api_name: default_ev_density + "_Minimum",
    business_name: "Minimum density",
  },
  {
    api_name: default_ev_density + "_Maximum",
    business_name: "Maximum density",
  },
  {
    api_name: default_ev_density + "_Mean",
    business_name: "Average density",
  },
  // charging stations density
  {
    api_name: cs_density + "_Count",
    business_name: "Count",
  },
  {
    api_name: cs_density + "_Total",
    business_name: "Total density",
  },
  {
    api_name: cs_density + "_Mean",
    business_name: "Average density",
  },
  // eurostats cities isochrones
  {
    api_name: rasterized_iso_urban + "_Total",
    business_name: "Total",
  },
  {
    api_name: rasterized_iso_urban + "_Minimum",
    business_name: "Minimum",
  },
  {
    api_name: rasterized_iso_urban + "_Maximum",
    business_name: "Maximum",
  },
  {
    api_name: rasterized_iso_urban + "_Mean",
    business_name: "Average",
  },
  // smaller cities isochrones
  {
    api_name: rasterized_iso_rural + "_Total",
    business_name: "Total",
  },
  {
    api_name: rasterized_iso_rural + "_Minimum",
    business_name: "Minimum",
  },
  {
    api_name: rasterized_iso_rural + "_Maximum",
    business_name: "Maximum",
  },
  {
    api_name: rasterized_iso_rural + "_Mean",
    business_name: "Average",
  },
  // transborder commuter ratio
  {
    api_name: rasterized_border + "_Mean",
    business_name: "Average",
  },
  // eurostat cities id
  {
    api_name: rasterized_urau + "_Minimum",
    business_name: "Minimum",
  },
  {
    api_name: rasterized_urau + "_Maximum",
    business_name: "Maximum",
  },
  // smaller cities id
  {
    api_name: rasterized_urau_rural + "_Minimum",
    business_name: "Minimum",
  },
  {
    api_name: rasterized_urau_rural + "_Maximum",
    business_name: "Maximum",
  },
  // workplaces from openstreetmap
  {
    api_name: work_osm + "_Total",
    business_name: "Total",
  },
  {
    api_name: work_osm + "_Minimum",
    business_name: "Minimum",
  },
  {
    api_name: work_osm + "_Maximum",
    business_name: "Maximum",
  },
  {
    api_name: work_osm + "_Mean",
    business_name: "Average",
  },
  // working areas from corine land cover
  {
    api_name: filtered_corine_land_cover + "_Total",
    business_name: "Total",
  },
  // point of interest
  {
    api_name: poi_osm + "_Total",
    business_name: "Total",
  },
  {
    api_name: poi_osm + "_Minimum",
    business_name: "Minimum",
  },
  {
    api_name: poi_osm + "_Maximum",
    business_name: "Maximum",
  },
  {
    api_name: poi_osm + "_Mean",
    business_name: "Average",
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Potentials
  {
    api_name: wwtp_capacity + "_capacity",
    business_name: "Waste water treatment capacity",
  },
  {
    api_name: wwtp_power + "_power",
    business_name: "Average excess heat power from waste water treatment",
  },

  {
    api_name: solar_optimal_total + "_average",
    business_name: "Average solar radiation",
  },
  {
    api_name: solar_optimal_total + "_min",
    business_name: "Minimum solar radiation",
  },
  {
    api_name: solar_optimal_total + "_max",
    business_name: "Maximum solar radiation",
  },
  {
    api_name: solar_optimal_total + "_cells",
    business_name: "Counted cells",
  },

  {
    api_name: potential_solarthermal_collectors_rooftop + "_average",
    business_name: "Average solar thermal potential - rooftop",
  },
  {
    api_name: potential_solarthermal_collectors_rooftop + "_min",
    business_name: "Min solar thermal potential - rooftop",
  },
  {
    api_name: potential_solarthermal_collectors_rooftop + "_max",
    business_name: "Max solar thermal potential - rooftop",
  },
  {
    api_name: potential_solarthermal_collectors_rooftop + "_cells",
    business_name: "Counted cells",
  },
  {
    api_name: potential_solarthermal_collectors_rooftop + "_total",
    business_name: "Total solar thermal potential - rooftop",
  },

  {
    api_name: potential_solarthermal_collectors_open_field + "_average",
    business_name: "Average solar thermal potential - open field",
  },
  {
    api_name: potential_solarthermal_collectors_open_field + "_min",
    business_name: "Min solar thermal potential - open field",
  },
  {
    api_name: potential_solarthermal_collectors_open_field + "_max",
    business_name: "Max solar thermal potential - open field",
  },
  {
    api_name: potential_solarthermal_collectors_open_field + "_cells",
    business_name: "Counted cells",
  },
  {
    api_name: potential_solarthermal_collectors_open_field + "_total",
    business_name: "Total solar thermal potential - open field",
  },

  {
    api_name: wind_50m + "_average",
    business_name: "Average wind speed",
  },
  { api_name: wind_50m + "_min", business_name: "Minimum wind speed" },
  { api_name: wind_50m + "_max", business_name: "Maximum wind speed" },
  { api_name: wind_50m + "_cells", business_name: "Counted cells" },

  {
    api_name: potential_potential_shallowgeothermal + "_value",
    business_name: "Average heat conductivity",
  },

  {
    api_name: solid_waste + "_value",
    business_name: "Potential from Waste - maximum resolution: NUTS3",
  },

  {
    api_name: agricultural_residues_view + "_NUTS_potential",
    business_name:
      "Potential from agricultural residues - maximum resolution: NUTS3",
  },
  //{ api_name: agricultural_residues_view + '_agriculture_pp', business_name: 'Potential from agricultural residues per capita'},

  {
    api_name: livestock_effluents_view + "_NUTS_potential",
    business_name:
      "Potential from livestock effluents - maximum resolution: NUTS3",
  },
  //{ api_name: livestock_effluents_view + '_livestock_effluents_pp', business_name: 'Potential from livestock_effluents_view per capita'},

  {
    api_name: potential_forest + "_average",
    business_name: "Average potential from forest resdiues",
  },
  {
    api_name: potential_forest + "_value",
    business_name: "Potential from forest resdiues",
  },
  {
    api_name: potential_forest + "_cells",
    business_name: "Counted cells",
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Climate
  { api_name: cdd_curr + "_average", business_name: "Average CDD" },
  { api_name: cdd_curr + "_max", business_name: "Maximum CDD" },
  { api_name: cdd_curr + "_min", business_name: "Minimum CDD" },
  { api_name: cdd_curr + "_cells", business_name: "Counted cells" },

  { api_name: hdd_curr + "_average", business_name: "Average HDD" },
  { api_name: hdd_curr + "_max", business_name: "Maximum HDD" },
  { api_name: hdd_curr + "_min", business_name: "Minimum HDD" },
  { api_name: hdd_curr + "_cells", business_name: "Counted cells" },

  {
    api_name: land_surface_temperature + "_average",
    business_name: "Average Temperature",
  },
  {
    api_name: land_surface_temperature + "_maximum",
    business_name: "Maximum Temperature",
  },
  {
    api_name: land_surface_temperature + "_minimum",
    business_name: "Minimum Temperature",
  },
  {
    api_name: land_surface_temperature + "_cells",
    business_name: "Counted cells",
  },

  {
    api_name: solar_radiation + "_average",
    business_name: "Average solar radiation",
  },
  {
    api_name: solar_radiation + "_min",
    business_name: "Minimum solar radiation",
  },
  {
    api_name: solar_radiation + "_max",
    business_name: "Maximum solar radiation",
  },
  {
    api_name: solar_radiation + "_cells",
    business_name: "Counted cells",
  },
  {
    api_name: solar_radiation + "_total_radiation",
    business_name: "Total radiation on surface",
  },

  {
    api_name: output_wind_speed + "_average",
    business_name: "Average wind speed",
  },
  {
    api_name: output_wind_speed + "_min",
    business_name: "Minimum wind speed",
  },
  {
    api_name: output_wind_speed + "_max",
    business_name: "Maximum wind speed",
  },
  {
    api_name: output_wind_speed + "_cells",
    business_name: "Counted cells",
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Electricity

  {
    api_name: yearly_co2_emission + "_density",
    business_name: "Average CO2 emissions on NUTS0",
  },
];
