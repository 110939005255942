import { Injectable } from "@angular/core";
import { Helper } from "@core/services/helper";
import { isNullOrUndefinedString } from "@services/core.utilities";

@Injectable()
export class SelectionToolUtils {
  constructor(private _helper: Helper) {}

  getLocationsFromCicle(layer): any {
    return this._helper.getLocationsFromCircle(layer);
  }

  getIdSelectionFromLayer(layer: any): any {
    return this.getSelectionIdFromLayer(layer);
  }
  getSelectionIdFromLayer(layer): any {
    let id_selection = this._helper.getNUTSIDFromGeoJsonLayer(layer);
    if (isNullOrUndefinedString(id_selection) === true) {
      id_selection = this._helper.getLAU2IDFromGeoJsonLayer(layer);
      if (isNullOrUndefinedString(id_selection) === true) {
        return null;
      }
      return id_selection;
    }
    return id_selection;
  }

  layersAsLayer(layers): boolean {
    let hasLayer = false;
    if (layers.getLayers().length > 0) {
      hasLayer = true;
    }
    return hasLayer;
  }
}
