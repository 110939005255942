<div class="search-bar">
  <i class="icon-search fa fa-search"><button uk-icon="search"></button></i>
  <input
    id="place-input"
    class="on-map"
    type="text"
    placeholder="Go to place..."
    [(ngModel)]="address"
    (ngModelChange)="options($event)"
    (keyup.enter)="goto()"
    list="results"
    autocomplete="one-time-code"
  />
  <datalist id="results">
    <option
      *ngFor="let r of results; let i = index"
      data-value="i"
      [value]="r.label"
    >
      {{ r.label }}
    </option>
  </datalist>
  <button
    class="recenter-button"
    (click)="recenterMap()"
    matTooltip="Recenter map"
  >
    <svg width="20" height="20" viewBox="0 0 20 20">
      <circle fill="none" stroke="#000" cx="10" cy="10" r="7.5" />
      <line fill="none" stroke="#000" x1="10" x2="10" y2="8" />
      <line fill="none" stroke="#000" x1="10" y1="12" x2="10" y2="20" />
      <line fill="none" stroke="#000" y1="10" x2="8" y2="10" />
      <line fill="none" stroke="#000" x1="12" y1="10" x2="20" y2="10" />
    </svg>
    <!-- <span>Recenter</span> -->
  </button>
</div>

<!--<button id="goto" class="btn btn-primary on-map" href="#" title="Goto Place" (click)="goto()"></button>-->
