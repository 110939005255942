<div
  [ngClass]="
    mapComponent.openedTabs[3] ? 'sidetab sidetabSelected' : 'sidetab '
  "
  (click)="openTab()"
>
  Scenario explorer
</div>
<div
  *ngIf="scenarioOpen"
  id="layertab"
  [ngClass]="
    mapComponent.openedTabs[3] ? 'layertabWrapper tabopen' : 'layertabWrapper'
  "
>
  <div class="header">
    <h3>Scenario explorer</h3>
    <p (click)="closeTab()">x</p>
  </div>
  <p>Scenarios</p>
  In development...
</div>
