<div id="modal-full" class="uk-modal-full" uk-modal style="font-size: small">
  <!-- TODO add style to css file instead of html-->
  <div class="uk-modal-dialog">
    <button
      class="uk-modal-close-full uk-close-large"
      type="button"
      uk-close
    ></button>
    <div class="uk-grid-collapse uk-child-width-1-1@s uk-flex-middle" uk-grid>
      <!-- <div class="uk-background-cover" uk-height-viewport>
      </div> -->
      <div class="uk-padding large main-container">
        <div class="uk-column-1-2">
          <div class="logo-container">
            <img [src]="this.logoUrl" alt="Logo" class="logo" />
          </div>
          <div *ngIf="partnersLogosUrl != ''" class="logo-container partners">
            <img [src]="partnersLogosUrl" alt="Partner's logos" />
          </div>
        </div>

        <p class="intro-text">
          <span *ngIf="isDevOrLocalhost !== ''">
            <h1 class="dev-mode-title">{{ isDevOrLocalhost }} platform :</h1>
            <p class="dev-mode-subtitle">
              You are on the development version of the platform. Your data may
              be deleted at any time.
            </p>
          </span>
          {{ introText }}
          <br />
          More information on the project can be found here:
          <a class="uk-link-heading" [href]="websiteUrl" target="_blank">{{
            websiteUrl
          }}</a>
        </p>
        <p *ngIf="wikiUrl != ''">
          <strong>Wiki:</strong> For details about tool functionalities as well
          as the manual for using the calculation modules, please refer to the
          Wiki:
          <a class="uk-link-heading" [href]="wikiUrl" target="_blank">{{
            wikiUrl
          }}</a>
        </p>
        <p>
          <strong>Open source:</strong>
          The developed tool and all related modules run without requiring any
          other commercial tool or software. Use of and access to Source Code is
          subject to Open Source Licenses.
          <span *ngIf="dataSetURl != ''"
            >The datasets that are used in the tool can be found at
            <a class="uk-link-heading" [href]="dataSetURl" target="_blank">{{
              dataSetURl
            }}</a
            >.
          </span>

          <span *ngIf="repositoryUrl != ''">
            The source code of the tool can be accessed via
            <a class="uk-link-heading" [href]="repositoryUrl" target="_blank">{{
              repositoryUrl
            }}</a
            >.
          </span>
        </p>
        <p *ngIf="enableFeedback">
          <strong>Feedback and contributions:</strong>
          We are happy to receive feedback on the tool. Please use the
          feedback-button in the toolbox to let us know your opinions and
          suggestions for the tool. {{ appName }} also invites other developers
          to contribute with additional calculation modules or datasets that can
          be integrated in the toolbox. Please also get in contact with us if
          you want to take part in the future development of the platform.
        </p>
        <div>
          <strong>Supported browsers:</strong>
          <ul class="nolist">
            <li>
              <img
                class="supported-browser"
                src="assets/first-page/firefox.png"
                alt="firefox logo"
              />
              <span class="browser-label">Firefox version 76.0.1</span>
            </li>
            <li>
              <img
                class="supported-browser"
                src="assets/first-page/chrome.png"
                alt="chrome logo"
              />
              <span class="browser-label">Chrome version 83.0.4103.61</span>
            </li>
          </ul>
        </div>
        <p>
          <strong>Disclaimer:</strong>
          The data and tools provided on this website are indicative and for
          research purposes only. No responsibility is taken for the accuracy of
          included data, figures and results or for using them for unintended
          purposes.
        </p>
        <p>
          <strong>Data privacy:</strong> By clicking ACCEPT ALL below, you
          accept that this website may use cookies.
        </p>
        <p class="uk-text-right">
          <button
            class="uk-button uk-button-default uk-modal-close"
            type="button"
          >
            Decline All
          </button>
          <button
            class="uk-button uk-button-primary uk-modal-close"
            type="button"
            (click)="this.cookies.setCookiesAccepted()"
          >
            Accept All
          </button>
        </p>
      </div>
    </div>
  </div>
</div>
