/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 27.05.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Location } from "@core/models/location/location";
import { APIService } from "@core/services/api.service";
import { BusinessInterfaceRenderService } from "@core/services/business/business.service";
import { Helper } from "@core/services/helper";
import { InteractionService } from "@core/services/interaction.service";
import { LoaderService } from "@core/services/loader.service";
import { Logger } from "@core/services/logger.service";
import { ToasterService } from "@core/services/toaster.service";
import { isNullOrUndefinedString } from "@services/core.utilities";
import {
  geoserverUrl,
  hectare,
  initial_scale_value,
  lau2name,
  maxSurfaceValueCM,
} from "@services/data.service";
import { environment } from "environments/environment";
import * as Leaflet from "leaflet";
import * as proj4x from "proj4";
import { BehaviorSubject, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { SelectionToolUtils } from "./selection-tool-utils.service";
const proj4 = (proj4x as any).default;
declare const L: any;

@Injectable()
export class SelectionToolService extends APIService {
  private _nutsIds: Set<string> = new Set();
  private _isActivate: boolean;
  private _multiSelectionLayers: Leaflet.FeatureGroup =
    new Leaflet.FeatureGroup();
  private _controlMultiLayer: Leaflet.FeatureGroup = new Leaflet.FeatureGroup();
  private _currentLayer;
  private _scaleValue = initial_scale_value;
  private _theDrawer;
  private _isDrawer = false;
  private _isPolygonDrawer = false;
  private _nbNutsSelectedSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private _surfaceSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  buttonLoadResultStatus = new BehaviorSubject<boolean>(false);
  buttonClearAll = new BehaviorSubject<boolean>(false);

  nutsIdsSubject = new BehaviorSubject<string[]>([]);
  locationsSubject = new BehaviorSubject<Location[]>([]);
  areasSubject = new BehaviorSubject<Leaflet.Layer[]>([]);
  nbOfLayersSelected: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    http: HttpClient,
    loaderService: LoaderService,
    toasterService: ToasterService,
    private _helper: Helper,
    private _logger: Logger,
    private _businessInterfaceRenderService: BusinessInterfaceRenderService,
    private _interactionService: InteractionService,
    private _selectionToolUtils: SelectionToolUtils
  ) {
    super(http, _logger, loaderService, toasterService);
  }

  setIsPolygonDrawer(value) {
    this._isPolygonDrawer = value;
  }
  getNutsSelectedSubject(): Subject<number> {
    return this._nbNutsSelectedSubject;
  }
  getSelectionSurface(): BehaviorSubject<number> {
    return this._surfaceSubject;
  }
  getMultiSelectionLayers(): any {
    return this._multiSelectionLayers;
  }
  setLoadresultButton(value) {
    this.buttonLoadResultStatus.next(value);
  }
  getControlMultiLayer(): any {
    return this._controlMultiLayer;
  }

  setButtonClearAll(value) {
    this.buttonClearAll.next(value);
  }

  drawCreated(e, myMap, nuts_lvl) {
    const event: Leaflet.DrawEvents.Created = <Leaflet.DrawEvents.Created>e;
    const type = event.layerType,
      layer: any = event.layer;
    let controlIntersectContainsHectar = false;
    this._isActivate = false;
    this._isPolygonDrawer = false;
    let location = "";
    this.logger.log("selection-tool.service/drawCreated scale lvl ", nuts_lvl);

    //@todo: give relative parameter to getNutID
    if (nuts_lvl === 5) {
      // Hectare case
      controlIntersectContainsHectar = this._helper.controlDrawedLayer(
        this._multiSelectionLayers,
        layer
      );
      if (!controlIntersectContainsHectar) {
        location = this._helper.convertPostGisLatLongToLocationString(
          this._helper.getLocationsFromLayer(layer)
        );
        this.drawHectaresLoadingResult(myMap, layer);
      }
    } else {
      location = this._helper.convertLatLongToLocationString(
        this._helper.getLocationsFromLayer(layer)
      );
      if (nuts_lvl === 4) {
        // LAU 2 Case
        this.getNutID(location, myMap, nuts_lvl, lau2name);
      } else if (nuts_lvl == 6) {
        //Country level
        this.getNutID(location, myMap, nuts_lvl, "boundaries0");
      } else if (nuts_lvl == 7) {
        //Region level
        this.getNutID(location, myMap, nuts_lvl, "regions");
      } else if (nuts_lvl == 8) {
        //Sub-division level and default
        this.getNutID(location, myMap, nuts_lvl, "boundaries1");
      } else {
        this.getNutID(location, myMap, nuts_lvl, "population");
      }
    }
  }

  setScaleValue(scaleValue: string) {
    this._scaleValue = scaleValue;
  }
  getScaleValue(): string {
    return this._scaleValue;
  }

  loadResultNuts(myMap) {
    const layerNameArray = [];
    for (
      let i = 0;
      i < this._interactionService.getLayerArray().keys().length;
      i++
    ) {
      layerNameArray.push(
        this._interactionService.getLayerArray().keys()[i] +
          this._businessInterfaceRenderService.getNutsTosuffix(this._scaleValue)
      );
    }
    this.logger.log(
      "selection-tool.service/loadResultNuts layerNameArray ",
      layerNameArray
    );
    this.getStatistics();
    this.setAreas();
  }

  removeLayerFromMultiSelectionLayers(layer: any) {
    this.logger.log(
      "selection-tool.service/removeLayerFromMultiSelectionLayers"
    );
    // if the nut
    const self = this;
    const id_selection =
      self._selectionToolUtils.getSelectionIdFromLayer(layer);
    this._nutsIds.delete(id_selection);
    let indexToRemove = 999;
    for (let i = 0; i < this._multiSelectionLayers.getLayers().length; i++) {
      const iDInMultiSelectionLayers =
        self._selectionToolUtils.getSelectionIdFromLayer(
          this._multiSelectionLayers.getLayers()[i]
        );
      if (id_selection === iDInMultiSelectionLayers) {
        indexToRemove = i;
        break;
      }
    }
    if (indexToRemove !== 999) {
      this._multiSelectionLayers.removeLayer(
        this._multiSelectionLayers.getLayers()[indexToRemove]
      );

      if (this._controlMultiLayer.getLayers().length > 0) {
        //Check nessecary since leaflet 1.8. Otherwise throws error if no layers in group.
        this._controlMultiLayer.removeLayer(
          this._controlMultiLayer.getLayers()[indexToRemove]
        );
      }
    }
    this.updateSelectionToolAction();
  }

  containLayer(layer: any): number {
    if (!isNullOrUndefinedString(layer._leaflet_id)) {
      if (
        this._nutsIds.has(
          this._selectionToolUtils.getIdSelectionFromLayer(layer)
        )
      ) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 2;
    }
  }

  toggleActivateTool(val: boolean) {
    this._isActivate = val;
  }

  clearAll(myMap: any) {
    this.logger.log(
      "selection-tool-service/clearAll",
      "Clear all is called from selection tool"
    );
    this.nbOfLayersSelected.next(0);
    // ================
    /* //commented because selection tool is not fully reset when changing nuts: with this commented, everything is clear EXCEPT the selection tool
    if (this._isDrawer) {
      this._theDrawer.disable(); // Disable the actual drawer anyway and
      this._isPolygonDrawer = false;
    }
    */
    this._interactionService.closeRightPanel();
    // remove all layers selected
    this._multiSelectionLayers.clearLayers();
    this._controlMultiLayer.clearLayers();
    this._surfaceSubject.next(0);
    // remove all nutsID selected
    this._nutsIds.clear();
    this.updateSelectionToolAction();
    this._interactionService.deleteCMTask();
    // close opened CM
    // this.interactionService.setStatusCMPanel(false)
  }

  // Summary result show result
  getStatisticsFromLayer(locations: Location[], layers: string[], myMap: any) {
    const self = this;
    self.locationsSubject.next(locations);
    self.getStatistics();
  }

  getStatistics() {
    if (
      !(
        this._surfaceSubject.value > maxSurfaceValueCM &&
        this._scaleValue == hectare
      )
    ) {
      this._interactionService.openRightPanel();
    } else {
      this.toasterService.showToasterSurfaceCalculDisabled();
    }
  }

  toggleControl(myMap: any) {
    if (this._isDrawer) {
      this._theDrawer.disable(); // Disable the actual drawer
    } else {
      this._isDrawer = true;
    }
  }

  activateClickSelectionTool() {
    if (this._isDrawer) {
      this.getDrawer().disable(); // disable the current drawer before creating a new one
    }
    this._isPolygonDrawer = false;
  }

  activateDrawTool(myMap: Leaflet.DrawMap, tool: string) {
    if (this._isDrawer) {
      this.getDrawer().disable(); // disable the current drawer before creating a new one
    }
    // TODO: Modifier et mettre dans tableau
    switch (tool) {
      case "rectangle":
        this._theDrawer = new Leaflet.Draw.Rectangle(myMap, <
          Leaflet.DrawOptions.RectangleOptions
        >{ shapeOptions: { showArea: false } });
        this._isPolygonDrawer = false;
        break;
      case "circle":
        this._theDrawer = new Leaflet.Draw.Circle(myMap);
        this._isPolygonDrawer = false;
        break;

      case "polygon":
        this._theDrawer = new Leaflet.Draw.Polygon(myMap);
        this._isPolygonDrawer = true;
        break;
      default:
        break;
    }
    this._theDrawer.enable();

    this._isDrawer = true;
    //this.isPolygonDrawer = true;
  }

  getDrawer() {
    return this._theDrawer;
  }

  getPolygonDrawerState(): boolean {
    return this._isPolygonDrawer;
  }

  getNutID(location, myMap, nuts_lvl, stringLayerType) {
    this.loaderService.display(true);
    this.logger.log("selection-tool.service/getNutID", nuts_lvl);
    const epsg = "4326";
    const coordinate = location;
    let url =
      geoserverUrl +
      "?service=wfs" +
      "&version=2.0.0" +
      "&request=GetFeature" +
      "&srsName=EPSG:" +
      epsg +
      "&typeNames=" +
      environment.prefixWorkspaceName +
      stringLayerType +
      "&outputFormat=application/json" +
      "&CQL_FILTER= " +
      environment.nuts_CQL_FILTER +
      coordinate.toString() +
      "))))";
    if (nuts_lvl < 4) {
      url += "AND stat_levl_=" + nuts_lvl + "AND date=2015-01-01Z";
    }

    this.logger.log("selection-tool.service/getNutID url", url);
    this.GET(url)
      .pipe(map((res: Response) => res as any))
      .subscribe(
        (res) => this.drawResultBeforeLoadingResult(res),
        (err) => super.handleError(err)
      );
  }

  updateSelectionToolAction() {
    if (this._nutsIds.size > 0) {
      this.setButtonsSelectionToolState(true);
      this.defineSurface(this._multiSelectionLayers);
    } else {
      // disable buttons after clear
      this.setButtonsSelectionToolState(false);
      this.defineSurface(this._multiSelectionLayers);
    }
    this._nbNutsSelectedSubject.next(this._nutsIds.size);
    this.nutsIdsSubject.next(Array.from(this._nutsIds));
  }

  setButtonsSelectionToolState(value) {
    this.setLoadresultButton(value);
    this.setButtonClearAll(value);
  }

  updateSelectionToolActionHectare() {
    if (this.areasSubject.getValue().length > 0) {
      this.setButtonsSelectionToolState(true);
      this.defineSurface(this._controlMultiLayer);
    } else {
      // disable buttons after clear
      this.setButtonsSelectionToolState(false);
    }
  }

  defineSurface(layergroup) {
    var surface = 0;

    var bounds = layergroup.getBounds();
    if (
      (this._nutsIds.size > 0 || this.areasSubject.getValue().length > 0) &&
      JSON.stringify(bounds) != "{}"
    ) {
      var width =
        bounds.getNorthWest().distanceTo(bounds.getNorthEast()) / 1000;
      var height =
        bounds.getSouthEast().distanceTo(bounds.getNorthEast()) / 1000;
      surface = Math.round(width * height);
    }
    if (surface >= maxSurfaceValueCM) {
      this._interactionService.setStatusCMPanel(false);
      if (this._scaleValue == hectare) {
        this._interactionService.closeRightPanel();
      }
      this.toasterService.showToasterSurfaceCalculDisabled();
    }
    this._surfaceSubject.next(surface);
  }

  setAreas() {
    this.areasSubject.next(this._multiSelectionLayers.getLayers());
  }

  setLayerDependingCircleForControl(layer) {
    let layerInMultiSelection;
    if (layer instanceof Leaflet.Circle) {
      layerInMultiSelection = Leaflet.polygon([
        this._helper.getLocationsFromCircle(layer),
      ]);
    } else {
      layerInMultiSelection = layer;
    }

    layerInMultiSelection._leaflet_id = layer._leaflet_id;
    return layerInMultiSelection;
  }

  drawHectaresLoadingResult(myMap: Leaflet.Map, layer: any) {
    if (this._multiSelectionLayers.hasLayer(layer) === false) {
      const layerTemp = this.setLayerDependingCircleForControl(layer);
      this._controlMultiLayer.addLayer(layerTemp);
      this._multiSelectionLayers.addLayer(layer);
      this.areasSubject.next(this._multiSelectionLayers.getLayers());
      this.logger.log(
        "selection-tool.service/drawHectaresLoadingResult length",
        this.areasSubject.getValue().length
      );
      this._nbNutsSelectedSubject.next(this.areasSubject.getValue().length);
      this.updateSelectionToolActionHectare();
      this.loaderService.display(false);

      const self = this;
      layer.on("click", function () {
        if (layer.options.fillColor === null) {
          layer.setStyle({
            fillColor: "red",
          });
          self.nbOfLayersSelected.next(self.nbOfLayersSelected.value + 1);
        } else {
          layer.setStyle({
            fillColor: null,
          });
          self.nbOfLayersSelected.next(self.nbOfLayersSelected.value - 1);
        }
        /* const self = this;
          if (layer.editing.enabled()) {
            layer.editing.disable();
            self.updateControlLayers(layer);
            myMap.fire('draw:editstop');
          } else {
            layer.editing.enable();
            myMap.fire('draw:editstart');
          } */
      });
    }
  }

  drawResultBeforeLoadingResult(result: any) {
    this.logger.log("drawResultBeforeLoadingResult", result);
    /*if (result.features.length === 0) {
        this.toasterService.showToaster('We encountered a problem, there is no data for this area');
      }*/
    if (isNullOrUndefinedString(result) === false) {
      for (const feature of result.features) {
        let selection_id;
        //@todo: remove if africa
        if (environment.continent == "Africa") {
          if (!isNullOrUndefinedString(feature.properties.gid_2)) {
            selection_id = feature.properties.gid_2;
          } else if (!isNullOrUndefinedString(feature.properties.gid_1)) {
            selection_id = feature.properties.gid_1;
          } else {
            selection_id = feature.properties.gid_0;
          }
          if (this._nutsIds.has(selection_id) === false) {
            this._nutsIds.add(selection_id);
            const areaNutsSelectedLayer = L.geoJson(feature);
            this._multiSelectionLayers.addLayer(areaNutsSelectedLayer);
          }
        } else {
          if (isNullOrUndefinedString(feature.properties.nuts_id) === false) {
            selection_id = feature.properties.nuts_id;
          } else {
            selection_id = feature.properties.comm_id;
          }
          if (this._nutsIds.has(selection_id) === false) {
            this._nutsIds.add(selection_id);
            const areaNutsSelectedLayer = L.geoJson(feature);
            this._multiSelectionLayers.addLayer(areaNutsSelectedLayer);
          }
        }
      }
      const self = this;
    }
    this.updateSelectionToolAction();
    this.loaderService.display(false);
  }

  addToMultiSelectionLayers(layer: any) {
    if (isNullOrUndefinedString(layer) === false) {
      const selection_id =
        this._selectionToolUtils.getSelectionIdFromLayer(layer);
      if (this._nutsIds.has(selection_id) === false) {
        this._nutsIds.add(selection_id);
        this._multiSelectionLayers.addLayer(layer);
        this.updateSelectionToolAction();
      }
    }
  }

  addHectareToMultiSelectionLayers(layer: any) {
    if (isNullOrUndefinedString(layer) === false) {
      this._multiSelectionLayers.addLayer(layer);
    }
  }

  deleteSelectedAreas() {
    this._multiSelectionLayers.getLayers().map((layer: any) => {
      if (layer.options.fillColor === "red") {
        this._multiSelectionLayers.removeLayer(layer);
        this._controlMultiLayer.removeLayer(layer);
      }
    });
    this.nbOfLayersSelected.next(0);
    this.setAreas();
    this._nbNutsSelectedSubject.next(this.areasSubject.getValue().length);
    this.defineSurface(this._controlMultiLayer);
  }

  drawShapeFromFile(myMap: Leaflet.Map, geoJson: any) {
    geoJson.features.forEach((feature) => {
      const geom = feature.geometry;
      if (geom.type == "MultiPolygon") {
        geom.coordinates.forEach((coord) => {
          const poly = Leaflet.polygon(
            Leaflet.GeoJSON.coordsToLatLngs(coord[0])
          );
          this.drawHectaresLoadingResult(myMap, poly);
        });
      } else if (geom.type == "Polygon") {
        const poly = Leaflet.polygon(
          Leaflet.GeoJSON.coordsToLatLngs(geom.coordinates[0])
        );
        this.drawHectaresLoadingResult(myMap, poly);
      } else {
        this._logger.log("shape not supported");
      }
    });
  }
}
