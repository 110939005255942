import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Logger } from "@core/services/logger.service";

@Injectable()
export class ImportDataService {
  constructor(private http: HttpClient, private _logger: Logger) {}

  importData(csvUrl: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get(csvUrl, { responseType: "text" }).subscribe(
        (data) => {
          const dataArray = this.parseCsvData(data);
          resolve(dataArray);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  private parseCsvData(csvText: string): any[] {
    const lines = csvText.split("\n");

    if (lines.length < 4) {
      this._logger.log("CSV does not contain enough lines to process.");
      return [];
    }

    const propertyNames = lines[2].split(";").map((name) => name.trim());

    const dataArray: any[] = [];

    for (let i = 3; i < lines.length; i++) {
      const row = lines[i].trim();

      if (row == '"OUTPUT"') {
        break;
      }

      if (row === "") {
        continue;
      }

      const values = row.split(";").map((value) => value.trim());
      const obj: any = {};

      for (let index = 0; index < propertyNames.length; index++) {
        let propertyName = propertyNames[index];
        let val = values[index] || null;

        // just in case (comment to delete after testing)
        propertyName = propertyName.replace(/^"(.*)"$/, "$1");
        val = val ? val.replace(/^"(.*)"$/, "$1") : val;

        obj[propertyName] = val;
      }

      dataArray.push(obj);
    }
    return dataArray;
  }
}
