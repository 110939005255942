import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InteractionService } from "@core/services/interaction.service";
import { MapComponent } from "@pages/map/map.component";
import { isNullOrUndefinedString } from "@services/core.utilities";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import { issue_levels, issue_type } from "../data-issue";
import { FeedbackService } from "../feedback.service";
import { AcceptCookiesComponent } from "@components/accept-cookies/accept-cookies.component";
import { CookiesService } from "@core/services/cookies.service";

@Component({
  standalone: true,
  selector: "htm-feedback",
  templateUrl: "feedback.component.html",
  styleUrls: ["feedback.component.css"],
  animations: [
    // Formulare trigger
    trigger("feedbackTrigger", [
      state("expanded", style({ opacity: 1 })),
      state("collapsed", style({ opacity: 0 })),
      transition("collapsed => expanded", animate("200ms 150ms linear")),
      transition("expanded => collapsed", animate("100ms linear")),
    ]),
  ],
  imports: [
    NgIf,
    NgForOf,
    FormsModule,
    ReactiveFormsModule,

    // Shared
    RecaptchaModule,
    RecaptchaFormsModule,
    NgClass,

    // Components
    AcceptCookiesComponent,
  ],
})
export class FeedbackComponent implements OnInit {
  @Input() expandedState;
  public issue_levels = issue_levels;
  public issue_type = issue_type;
  public type = null;
  public level = null;
  public files;
  @ViewChild("file_input") file_input: ElementRef;
  @ViewChild("file_string") file_string: ElementRef;
  submited = false;
  submitMessage = {
    msg: "",
    success: false,
  };
  feedbackLoader = false;

  constructor(
    private _interactionService: InteractionService,
    private _mapComponent: MapComponent,
    private _feedbackService: FeedbackService,
    private _cookies: CookiesService
  ) {}
  ngOnInit() {
    this.type = null;
    this.level = null;
  }

  resetForm(f) {
    this.file_input.nativeElement.value = "";
    this.file_string.nativeElement.value = "";
    f.reset();
  }
  makeNewRequest() {
    this.submited = false;
  }
  submit(f) {
    // this.feedbackLoader = true;
    if (f.valid) {
      this.sendRequest(f);
      this.submited = true;
    } else {
      this.feedbackLoader = false;
      this.submited = false;
    }
  }

  close() {
    this._mapComponent.feedBackExpanded = false;
  }
  onUploadFile(files) {
    this.files = files[0];
  }
  showError() {
    this._interactionService.showDangerToaster(
      "Unable to send the issue! Please, try later or send a mail to administrator"
    );
  }

  sendRequest(f) {
    this.submitMessage = {
      msg: "",
      success: false,
    };
    this.feedbackLoader = true;
    const fd = new FormData();
    if (!isNullOrUndefinedString(this.files)) {
      if (!this.isImage(this.files)) {
        this._interactionService.showDangerToaster(
          "The uploaded file is not an image"
        );
        this.feedbackLoader = false;
        this.submitMessage.msg =
          "The image you tried to upload is not valid.<br/> Please try to send a new request or contact an administrator";
        this.submitMessage.success = false;
        return;
      }

      fd.append(
        "file",
        this.files,
        this.removeSpecialCharacters(this.files.name)
      );
    }

    fd.append("firstname", f.value["name"]);
    fd.append("email", f.value["mail"]);
    fd.append("company", f.value["company"]);
    fd.append("feedback_type", f.value["type"].name);
    fd.append("feedback_priority", f.value["level"].name);
    fd.append("title", f.value["title"]);
    fd.append("description", f.value["description"]);

    this._feedbackService
      .sendFeedback(fd)
      .then((val) => {
        this.feedbackLoader = false;
        //this.resetForm(f);
        //this.close();
        this._interactionService.showToaster(val.message);
        this.submitMessage.msg =
          "Your request has been successfully sent to administrators.<br /> Thanks Citiwattser !";
        this.submitMessage.success = true;
      })
      .catch((e) => {
        this.showError();
        this.feedbackLoader = false;
        this.submitMessage.msg =
          "Something went wrong.<br/>Please try to send a new request or contact an administrator";
        this.submitMessage.success = false;
      });
  }

  removeSpecialCharacters(str: string) {
    return str.replace(/[^a-zA-Z0-9\s._-]/g, "");
  }

  isImage(file) {
    // List of image MIME types
    const mimeTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/webp",
      // Add more MIME types if needed
    ];

    return mimeTypes.includes(file.type);
  }
}
