<span class="center">
  <div *ngIf="!noIndicator && categoryDisplayed > 2" style="padding: 10px">
    <select
      class="uk-select"
      (ngModelChange)="changeResultsDisplay($event)"
      [(ngModel)]="selectedButton"
    >
      <ng-container *ngFor="let button of dropdown_btns">
        <option
          *ngIf="button.display"
          [selected]="button.ref === selectedButton.ref"
          [ngValue]="button"
        >
          {{ button.name }}
        </option>
      </ng-container>
    </select>
  </div>
</span>
<ul class="uk-tab" data-uk-tab="{connect:'#my-id3'}">
  <li id="tab1" (click)="tabSwitch(tab1)">
    <a href="#">INDICATORS</a>
  </li>
  <li
    id="tab2"
    [class.uk-disabled]="
      result.graphics.length === 0 && !durationCurvePayload && !heatLoadPayload
    "
    (click)="tabSwitch(tab2)"
  >
    <a href="#">CHARTS</a>
  </li>
</ul>
<ul id="my-id3" class="uk-switcher results-container">
  <li>
    <div *ngIf="result.indicators">
      <htm-summary-result
        [summaryResult]="result.indicators"
        [refSelected]="selectedButton.ref"
      ></htm-summary-result>
    </div>
    <div
      class="spinner"
      *ngIf="indicatorLoading || indicatorPersoLoading"
      uk-spinner="ratio: 3"
    ></div>
  </li>
  <li class="charts-tab">
    <div *ngIf="result.graphics">
      <div *ngFor="let graphic of result.graphics">
        <!-- <ng-container *ngIf="graphic.category == 'heatload'">
          <htm-heat-load-chart [heatLoadPayload]="heatLoadPayload"></htm-heat-load-chart>
        </ng-container> -->
        <ng-container *ngIf="graphic.category == 'energy_mix'">
          <htm-electricity-mix
            [datasets]="graphic.data"
            [labels]="graphic.labels"
            [isLoading]="graphic.isLoading"
          ></htm-electricity-mix>
        </ng-container>
        <ng-container *ngIf="graphic.category == cm_catedory">
          <htm-chart
            [datasets]="graphic.data"
            [type]="graphic.type"
            [title]="graphic.name"
            [labels]="graphic.labels"
            [options]="graphic.options"
            [isLoading]="graphic.isLoading"
          ></htm-chart>
        </ng-container>
      </div>
    </div>
    <!-- <div class="spinner" *ngIf="result.graphics.length === 0 && !durationCurvePayload && !heatLoadPayload" uk-spinner="ratio: 3"></div> -->
  </li>
</ul>
<div>
  <htm-export-data
    [graphics]="result.graphics"
    [indicators]="result.indicators"
    [refSelected]="selectedButton.ref"
    [graphState]="graphicsExportButtonState"
    [indicatorState]="indicatorExportButtonState"
    [tabSelected]="tabSelected"
  ></htm-export-data>
</div>
