/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 13.07.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}

export function isNullOrUndefinedString(x: any) {
  let result = false;
  if (x == null) {
    result = true;
  }

  if (x === null) {
    result = true;
  }
  if (typeof x === "undefined") {
    result = true;
  }
  return result;
}
