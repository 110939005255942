<div
  [ngClass]="
    mapComponent.openedTabs[0] ? 'sidetab sidetabSelected' : 'sidetab '
  "
  (click)="openTab()"
>
  Tools
</div>
<div
  *ngIf="toolsOpened"
  id="tooltab"
  [ngClass]="
    mapComponent.openedTabs[0] ? 'tooltabWrapper tabopen' : 'tooltabWrapper'
  "
>
  <div class="header">
    <h3>Tools</h3>
    <p (click)="closeTab()">x</p>
  </div>
  <div>
    <htm-selection-tool
      [selectionSurface]="selectionSurface"
      onclick="event.stopPropagation();"
    ></htm-selection-tool>
  </div>
  <div>
    <htm-snapshot-panel></htm-snapshot-panel>
  </div>
</div>
