import { Component } from "@angular/core";
import { environment } from "environments/environment";

@Component({
  standalone: true,
  selector: "app-browser-disclaimer",
  templateUrl: "./browser-disclaimer.component.html",
  styleUrls: ["./browser-disclaimer.component.css"],
})

// Redirection in app component not working
export class BrowserDisclaimerComponent {
  logoUrl = environment.logoUrl;
}
