import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class UserManagementStatusService {
  private _isUserLogged = new BehaviorSubject<boolean>(false);
  private _userToken = new BehaviorSubject<string>(null);
  private _username = new BehaviorSubject<string>(null);

  constructor() {
    const token = sessionStorage.getItem("token");
    if (token) {
      const user = sessionStorage.getItem("username");
      if (user) {
        this.setUsername(user);
      }
      this.setUserToken(token);
      this.setUserIsLoggedIn();
    }
  }

  getUserToken() {
    return this._userToken;
  }
  getIsUserLogged() {
    return this._isUserLogged;
  }
  getUsername() {
    return this._username;
  }
  setUserIsLoggedIn() {
    this._isUserLogged.next(true);
  }
  setUserIsLoggedOut() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("username");
    this._isUserLogged.next(false);
  }
  setUsername(username) {
    if (username) sessionStorage.setItem("username", username);
    else sessionStorage.removeItem("username");
    this._username.next(username);
  }
  setUserToken(token) {
    if (token) sessionStorage.setItem("token", token);
    else sessionStorage.removeItem("token");
    this._userToken.next(token);
  }
}
