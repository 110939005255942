import { CommonModule, DecimalPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { CMLayersService } from "@components/calculation-module/cm-layers.service";
import { CalculationModuleStatusService } from "@components/calculation-module/service/calculation-module-status.service";
import { CalculationModuleService } from "@components/calculation-module/service/calculation-module.service";
import { CalculationHeatLoadDividedService } from "@components/calculation-module/service/calculation-test.service";
import { ExportDataService } from "@components/export-data/service/export-data.service";
import { FeedbackService } from "@components/feedback/feedback.service";
import { MailService } from "@components/feedback/mail.service";
import { DurationCurveService } from "@components/graph/duration-curve/duration-curve.service";
import { ElectricityMixService } from "@components/graph/electricity-mix/service/electricity-mix.service";
import { HeatLoadAggregateService } from "@components/graph/heat-load/heat-load.service";
import { ImportDataService } from "@components/import-data/service/import-data.service";
import { DataInteractionService } from "@components/layers-interaction/layers-interaction.service";
import { SelectionToolButtonStateService } from "@components/selection-tools/service/selection-tool-button-state.service";
import { SelectionToolUtils } from "@components/selection-tools/service/selection-tool-utils.service";
import { SelectionToolService } from "@components/selection-tools/service/selection-tool.service";
import { SummaryResultService } from "@components/summary-result/summary-result.service";
import { UserManagementStatusService } from "@components/user-management/service/user-management-status.service";
import { UserManagementService } from "@components/user-management/service/user-management.service";
import { BusinessInterfaceRenderService } from "@core/services/business/business.service";
import { Helper } from "@core/services/helper";
import { InteractionService } from "@core/services/interaction.service";
import { PopulationService } from "@core/services/population/services/population.service";
import { SelectionScaleService } from "@core/services/selection-scale/selection-scale.service";
import { SnapshotService } from "@core/services/snapshot.service";
import { SideComponent } from "@layouts/side-panel/side-panel.component";
import { SidePanelService } from "@layouts/side-panel/side-panel.service";
import { MapService } from "@pages/map/services/map.service";
import { UppercaseFirstLetterPipe } from "@pipes/uppercase-first-letter.pipe";
import { GlobalErrorHandler } from "@services/error-handler";
import { GeocodingService } from "@services/geocoding.service";
import { LayersService } from "@services/layers/services/layers.service";
import { LoaderService } from "@services/loader.service";
import { Logger } from "@services/logger.service";
import { ToasterService } from "@services/toaster.service";
import { UploadService } from "@services/upload.service";
import { RecaptchaLoaderService } from "ng-recaptcha";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routes";

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, UppercaseFirstLetterPipe, SideComponent],
  providers: [
    DataInteractionService,
    PopulationService,
    GeocodingService,
    MapService,
    Logger,
    LoaderService,
    ToasterService,
    GlobalErrorHandler,
    Helper,
    SidePanelService,
    LayersService,
    SelectionToolService,
    SelectionToolButtonStateService,
    BusinessInterfaceRenderService,
    SummaryResultService,
    DecimalPipe,
    MailService,
    SelectionScaleService,
    InteractionService,
    HeatLoadAggregateService,
    SelectionToolUtils,
    ExportDataService,
    ImportDataService,
    DurationCurveService,
    ElectricityMixService,
    CalculationModuleService,
    CalculationModuleStatusService,
    CalculationHeatLoadDividedService,
    CMLayersService,
    UserManagementService,
    UserManagementStatusService,
    SnapshotService,
    FeedbackService,
    UploadService,
    RecaptchaLoaderService,
  ],
  imports: [
    // Angular Modules
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // HTTP
    HttpClientModule,

    // Routing
    RouterModule,
    AppRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
