import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { APIService } from "@core/services/api.service";
import { LoaderService } from "@core/services/loader.service";
import { Logger } from "@core/services/logger.service";
import { ToasterService } from "@core/services/toaster.service";
import { geoserverUrl, initial_scale_value } from "@services/data.service";
import { environment } from "environments/environment";
import * as L from "leaflet";
import { BehaviorSubject } from "rxjs";
import { SelectionScaleClass } from "./class/selection-scale.class";
import { SelectionScaleClassArray } from "./selection-scale.data";

@Injectable()
export class SelectionScaleService extends APIService implements OnInit {
  private _scaleValue = initial_scale_value;

  // scale value subject
  scaleValueSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    initial_scale_value
  );

  private _wms_request;
  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService
  ) {
    super(http, logger, loaderService, toasterService);
  }
  ngOnInit() {
    this.changeScale();
  }
  /**
   * Get and change the selected scale value
   */
  changeScale() {
    this.scaleValueSubject.next(this._scaleValue);
  }

  getDataInteractionServices(): Promise<SelectionScaleClass[]> {
    return Promise.resolve(SelectionScaleClassArray);
  }
  getScaleValue(): string {
    return this._scaleValue;
  }

  setScaleValue(scaleValue: string) {
    this._scaleValue = scaleValue;
  }

  getDataArrayServices(): SelectionScaleClass[] {
    return SelectionScaleClassArray;
  }

  getModulesServicesWithNewService(
    newService: SelectionScaleClass
  ): Promise<SelectionScaleClass[]> {
    const servicesArray: SelectionScaleClass[] = SelectionScaleClassArray;
    servicesArray.push(newService);
    return Promise.resolve(servicesArray);
  }

  getModulesServicesSlowly(): Promise<SelectionScaleClass[]> {
    return new Promise((resolve) => {
      // Simulate server latency with 2 second delay
      setTimeout(() => resolve(this.getDataInteractionServices()), 1000);
    });
  }
  getTilayer(option: any, loader): any {
    const wms_request = L.tileLayer.wms(geoserverUrl, option);
    wms_request.on("load", function () {
      // loader.display(false)
    });
    wms_request.on("tileunload", function () {});
    wms_request.on("tileloadstart", function () {
      // loader.display(true)
    });
    wms_request.on("tileerror", function () {
      // loader.display(false)
    });
    wms_request.on("loading", function () {});

    return wms_request;
  }
  getSelectionScaleMenu(map: any, loader): L.Control.Layers {
    const SelectionScale: any = {};

    // Getting nuts from the environment
    environment.scaleRequest.forEach((element) => {
      SelectionScale[element.label] = this.getTilayer(element.option, loader);
    });

    const overlayMaps = {};

    const control = L.control.layers(SelectionScale, overlayMaps, {
      collapsed: false,
      position: "topright",
      autoZIndex: false, // Prevents map layer to overlap region selection layer.
    });
    control.addTo(map);
    map.scaleControl = control;
    map.addLayer(SelectionScale[initial_scale_value]); // # Add this if you want to show, comment this if you want to hide it.-
    return control;
  }
  getTooltipMenu() {
    let selectionToolTips: string = "";

    environment.scaleRequest.forEach((element: any) => {
      if (!element.tooltip) return "";

      selectionToolTips += `${element.label}: ${element.tooltip}\n`;
    });

    return selectionToolTips;
  }
  getIdFromNuts(nuts_lvl): any {
    const SelectionScale = {
      "NUTS 0": 0,
      "NUTS 1": 1,
      "NUTS 2": 2,
      "NUTS 3": 3,
      "LAU 2": 4,
      Hectare: 5,
      Country: 6,
      Region: 7,
      "Sub-division": 8,
    };
    return SelectionScale[nuts_lvl];
  }
  getInitialScale(): string {
    return initial_scale_value;
  }
}
