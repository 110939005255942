<!-- Display only if has never been shown before -->
<htm-welcome-page *ngIf="!this.cookies.cookiesAccepted()"></htm-welcome-page>

<div class="title-map" [ngClass]="{ 'title-map-dev-mode': isDevOrLocalhost }">
  <div class="menuLeft">
    <div class="toolbox-logo">
      <a href="{{ websiteUrl }}" target="_blank" *ngIf="websiteUrl !== ''">
        <img [src]="this.logoUrl" />
      </a>
      <img [src]="this.logoUrl" *ngIf="websiteUrl == ''" />
      <a
        class="dev-mode-title"
        *ngIf="isDevOrLocalhost !== ''"
        uk-tooltip="title: You are on the development version of the platform. Your data may be deleted at any time.; pos: bottom"
        >{{ isDevOrLocalhost }} platform
        <span style="position: relative; top: -2px" uk-icon="icon: info;"></span
      ></a>
    </div>
    <app-file-management></app-file-management>
  </div>
  <hmt-search-bar></hmt-search-bar>
  <div class="menuRight">
    <div class="title-header" *ngIf="wikiUrl !== ''">
      <a
        href="{{ wikiUrl }}"
        (click)="(gaWiki)"
        target="_blank"
        uk-tooltip="title: Current wiki under construction. Hotmaps wiki is accessible instead (past website version); pos: bottom"
      >
        <span>Wiki</span>
      </a>
    </div>
    <div class="title-header" *ngIf="enableFeedback; else disableFeedback">
      <a (click)="openFeedback()">
        <span>Feedback</span>
      </a>
    </div>
    <ng-template #disableFeedback>
      <div class="title-header">
        <a
          uk-tooltip="title: In development; pos: bottom"
          class="link-disabled"
        >
          <span>Feedback</span>
        </a>
      </div>
    </ng-template>
    <htm-user-management *ngIf="enableLogin"></htm-user-management>
  </div>
</div>

<htm-feedback [expandedState]="feedBackExpanded"></htm-feedback>

<div class="sideMenu">
  <app-toolstab
    [toolsOpened]="openedTabs[0]"
    [selectionSurface]="selectionSurface"
  ></app-toolstab>
  <app-layerstab
    *ngIf="isLayerTabVisible"
    [layersOpened]="openedTabs[1]"
  ></app-layerstab>
  <app-cmtab
    *ngIf="isCMTabVisible"
    [selectionSurface]="selectionSurface"
    [scaleLevel]="scaleLevel"
    [cmsOpen]="openedTabs[2]"
  ></app-cmtab>
  <app-scenariotab
    *ngIf="isScenarioTabVisible"
    [scenarioOpen]="openedTabs[3]"
  ></app-scenariotab>
</div>

<div class="container-map">
  <div id="map" [class.click-disable]="isCMRunning"></div>

  <htm-right-side-panel
    [hidden]="!openRightSidebar"
    [nutsIds]="nutsIds"
    [layers]="layers"
    [personnalLayers]="personnalLayers"
    [scaleLevel]="scaleLevel"
    [locationsSelection]="locationsSelection"
    [areas]="areas"
  >
  </htm-right-side-panel>
</div>
