/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       : This pipe is used to format number with the correct format
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 28.09.2017     Albain Dufils - CREM               Creation
 * *
 ******************************************************************/

import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrUndefinedString } from "@services/core.utilities";

@Pipe({
  standalone: true,
  name: "numberformat",
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: string, args: any[]): any {
    var val_trans = "";
    if (!isNullOrUndefinedString(value)) {
      val_trans = value.split(",").join(" ").split(".").join(".");
    }
    return val_trans;
  }
}
