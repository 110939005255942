<span>
  <div class="uk-card uk-card-body htm-card">
    <table class="uk-table uk-table-divider uk-table-small">
      <thead>
        <tr class="header-summary">
          <th class="uk-table-shrink">Information</th>
          <th class="uk-table-shrink">Value</th>
        </tr>
      </thead>
      <ng-container *ngIf="summaryResult.summaryResult">
        <tbody
          htm-summary-result-container
          [results]="summaryResult.summaryResult"
          [refSelected]="refSelected"
        ></tbody>
      </ng-container>
      <ng-container *ngIf="summaryResult.cmResult">
        <tbody
          htm-summary-result-container
          [results]="summaryResult.cmResult"
          [refSelected]="refSelected"
        ></tbody>
      </ng-container>
      <ng-container *ngIf="summaryResult.personnalLayerResult">
        <tbody
          htm-summary-result-container
          [results]="summaryResult.personnalLayerResult"
          [refSelected]="refSelected"
        ></tbody>
      </ng-container>
    </table>
  </div>
</span>
