<h2 style="text-align: center">Privacy terms</h2>

<div class="section">
  <h3 class="title">Who is collecting the data ?</h3>
  <div class="text">
    <p>
      Data is stored by
      <a href="https://www.hevs.ch/">HES-SO Valais-Wallis</a> and is not given
      to third parties.
    </p>
  </div>
</div>

<div class="section">
  <h3 class="title">What data is being collected ?</h3>
  <div class="text">
    <p>The personal data we store, are:</p>
    <ul>
      <li>Your name</li>
      <li>Your email address</li>
      <li>Any uploaded data through the toolbox</li>
    </ul>
  </div>
</div>

<div class="section">
  <h3 class="title">How will the information be used ?</h3>
  <div class="text">
    <p>
      We use this information to identify different activities you perform on
      this website.
    </p>
    <p>
      You can view or modify this data at any time by visiting your user
      profile.
    </p>
  </div>
</div>
<div class="section">
  <h3 class="title">How long will the data be stored for ?</h3>
  <div class="text">
    <p>
      We store this data as long as your account is not removed. You can remove
      your account and thereby all your personal data at any time in your user
      profile.
    </p>
    <p>
      If you do not activate your account within 2 days, all data associated with your 
      registration will be deleted from our servers.
    </p>
  </div>
</div>
<div class="section">
  <h3 class="title">How can I delete my personal data ?</h3>
  <div class="text">
    <p>
      Just head to your account details by pressing your profile on the top right of your screen.
      Then, simply press the bin and validate that you wish your account to be deleted.
    </p>
  </div>
</div>
<div class="section">
  <h3 class="title">How can I raise a complaint ?</h3>
  <div class="text">
    <p>
      You can complain about privacy violations at
      <a href="https://www.dsb.gv.at" target="_blank">https://www.dsb.gv.at</a>
    </p>
  </div>
</div>
