import { CommonModule } from "@angular/common";
import {
  AfterContentInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FeedbackComponent } from "@components/feedback/component/feedback.component";
import { FileManagementComponent } from "@components/file-management/file-management.component";
import { SearchBarComponent } from "@components/searchbar/searchbar.component";
import { SelectionToolButtonStateService } from "@components/selection-tools/service/selection-tool-button-state.service";
import { SelectionToolService } from "@components/selection-tools/service/selection-tool.service";
import { UserManagementComponent } from "@components/user-management/component/user-management.component";
import { Location } from "@core/models/location/location";
import { InteractionService } from "@core/services/interaction.service";
import { UploadService } from "@core/services/upload.service";
import { CmtabComponent } from "@layouts/cmtab/cmtab.component";
import { LayerstabComponent } from "@layouts/layerstab/layerstab.component";
import { RightSideComponent } from "@layouts/resultstab/right-side-panel.component";
import { ScenariotabComponent } from "@layouts/scenariotab/scenariotab.component";
import { SidePanelService } from "@layouts/side-panel/side-panel.service";
import { ToolstabComponent } from "@layouts/toolstab/toolstab.component";
import { WelcomePageComponent } from "@layouts/welcome-page/welcome-page.component";
import { CookiesService } from "@services/cookies.service";
import {
  enableFeedback,
  eu_logo_height,
  map_options,
  wikiUrl,
} from "@services/data.service";
import { Logger } from "@services/logger.service";
import { DataInteractionArray } from "app/layers";
import { environment } from "environments/environment";
import { DrawMap, Layer, Map, control } from "leaflet";
import "leaflet-draw";
import { MapService } from "./services/map.service";
import layers = control.layers;
declare const L: any;

@Component({
  standalone: true,
  selector: "htm-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.less"],
  imports: [
    MapComponent,
    FeedbackComponent,
    UserManagementComponent,
    CommonModule,
    FormsModule,
    RouterModule,
    SearchBarComponent,
    RightSideComponent,
    FileManagementComponent,
    ToolstabComponent,
    CmtabComponent,
    LayerstabComponent,
    ScenariotabComponent,
    WelcomePageComponent,
  ],
})
export class MapComponent implements OnInit, AfterContentInit, OnDestroy {
  isSelectionToolVisible = false;
  selectionToolShow = false;

  isLayerTabVisible = false;
  isCMTabVisible = false;
  isScenarioTabVisible = false;

  websiteUrl = environment.websiteUrl;
  wikiUrl = wikiUrl;
  enableFeedback = enableFeedback;
  appName = environment.appName;
  logoUrl = environment.logoUrl;
  enableLogin = environment.enableLogin;
  nutsIds: string[];
  locationsSelection: Location[];
  areas: Layer[];
  private _map: DrawMap;
  layers;
  scaleLevel;
  // private cmRunned;
  personnalLayers;
  selectionSurface = 0;
  isCMRunning = false;
  openedTabs = [false, false, false, false];
  feedBackExpanded = false;
  @ViewChild(SearchBarComponent, { static: true })
  searchBarComponent: SearchBarComponent;

  // management of initial status of sidebar
  openRightSidebar = false;
  openRightToggleExpanded = false;
  openLeftSidebar = false;
  // declaration of the left and right sidebar
  @ViewChild(RightSideComponent, { static: true })
  rightPanelComponent: RightSideComponent;
  private _zoomlevel;
  isDevOrLocalhost = "";

  constructor(
    private _mapService: MapService,
    private _logger: Logger,
    private _panelService: SidePanelService,
    private _uploadService: UploadService,
    private _selectionToolButtonStateService: SelectionToolButtonStateService,
    private _selectionToolService: SelectionToolService,
    private _interactionService: InteractionService,
    public cookies: CookiesService
  ) {
    this.isDevOrLocalhost = this._mapService.setIsDevOrLocalHost();
  }

  setToolsOpened(): boolean[] {
    this.openedTabs = [true, false, false, false];
    return this.openedTabs;
  }
  setLayersOpened(): boolean[] {
    this.openedTabs = [false, true, false, false];
    return this.openedTabs;
  }
  setCMsOpened(): boolean[] {
    this.openedTabs = [false, false, true, false];
    return this.openedTabs;
  }
  setScenarioOpened(): boolean[] {
    this.openedTabs = [false, false, false, true];
    return this.openedTabs;
  }
  setTabsClosed(): boolean[] {
    this.openedTabs = [false, false, false, false];
    return this.openedTabs;
  }

  ngAfterContentInit(): void {
    this.notifySubscription();
    this.rightPanelComponent.setTitle("Results");
  }
  ngAfterViewChecked(): void {
    window.dispatchEvent(new Event("resize"));
  }
  ngOnDestroy() {
    this._map.remove();
  }
  notifySubscription() {
    if (this._uploadService.getActivePersonalLayers) {
      this._uploadService.getActivePersonalLayers().subscribe((lay) => {
        this.personnalLayers = Object.assign({}, lay);
      });
    }
    this._selectionToolService.getSelectionSurface().subscribe((surface) => {
      this.selectionSurface = surface;
      if (surface != 0 && !this.openedTabs.some((elem) => elem)) {
        this.setToolsOpened();
      }

      if (surface === 0) {
        this.openRightSidebar = false;
      }
    });
    if (this._mapService.getScaleValueSubject() !== null) {
      this._mapService.getScaleValueSubject().subscribe((scaleLevel) => {
        this.scaleLevel = this._mapService.getNutsBusiness(scaleLevel);
        this._mapService.setLayersSubject();
      });
    }
    this._interactionService.getCmRunning().subscribe((value) => {
      this.isCMRunning = value;
      if (value == true) {
        this._interactionService.openRightPanel();
      }

      this._mapService.setCMRunning(value);
    });

    if (this._mapService.getLayerArray() !== null) {
      this._mapService.getLayerArray().subscribe((data) => {
        this.layers = data;
      });
    }
    this._selectionToolButtonStateService.status.subscribe((val: Boolean) => {
      this._logger.log("mapComponent/selectionTool", val);
      if (val) {
        this.selectionToolShow = true;
      } else {
        this.selectionToolShow = false;
      }
    });
    this._selectionToolService.areasSubject.subscribe((areas) => {
      this.areas = areas;
    });
    this._selectionToolService.nutsIdsSubject.subscribe((data) => {
      this.nutsIds = data;
    });
    this._selectionToolService.locationsSubject.subscribe((data) => {
      this.locationsSelection = data;
    });
    this._panelService.rightToggleExpandedStatus.subscribe((val: boolean) => {
      //rightToggleExpandedStatus is never changed !!!
      if (this.openRightToggleExpanded === false) {
        this.openRightToggleExpanded = true;
      } else {
        this.rightPanelComponent.toggleExpandedState();
        this.openRightSidebar = val;
      }
    });
    if (this._mapService.getZoomLevel() !== null) {
      this._mapService.getZoomLevel().subscribe((zoomlevel) => {
        this._zoomlevel = zoomlevel;
      });
    }
    this._panelService.rightPanelStatus.subscribe((val: boolean) => {
      if (this.selectionSurface <= 0) {
        this.openRightSidebar = false;
        this.rightPanelComponent.displayPanel(false);
        return;
      }
      this.openRightSidebar = val;
      this.rightPanelComponent.displayPanel(val);
    });
    this._panelService.leftPanelStatus.subscribe((val: boolean) => {
      this.openLeftSidebar = val;
      // this.leftPanelComponent.display(val);
    });
  }
  ngOnInit() {
    // mapService get an instance of the maps and can work on it
    this._mapService.setupMapservice(this.createMap());

    this._map.invalidateSize();

    if (
      DataInteractionArray.filter((layer) =>
        layer.projects?.includes(environment.appName)
      ).length > 0
    ) {
      this.isLayerTabVisible = true;
    }
    if (environment.cmsId.length > 0) {
      this.isCMTabVisible = true;
    }

    if (environment.scenarioExplorer !== "") {
      this.isScenarioTabVisible = true;
    }
  }

  // main method create and display map (main purpose of this component)
  createMap(): DrawMap {
    //setup the map from leaflet
    this._map = L.map("map", map_options);

    if (environment.fundingText !== "" || environment.fundingImg !== "") {
      var eu_logo = L.control({ position: "bottomright", onAdd: () => {} });

      eu_logo.onAdd = function () {
        var div = L.DomUtil.create("div", "eu_logo");
        div.style.background = "rgba(255, 255, 255, 0.7)";
        div.style.padding = "5px";
        div.style.maxWidth = "500px";

        div.style.display = "flex";
        div.style.alignItems = "center";

        var logoContainer = document.createElement("div");
        var img = document.createElement("img");
        var textContainer = document.createElement("div");
        var textSpan = document.createElement("span");

        logoContainer.style.display = "flex";
        logoContainer.style.height = eu_logo_height + "px";
        logoContainer.style.justifyContent = "center";
        logoContainer.style.marginRight = "10px";

        // image styling
        img.src = environment.fundingImg;
        img.style.height = "100%";
        img.style.maxWidth = "100%";

        if (environment.fundingText !== "") {
          // text container styling
          textContainer.style.width = "300px";
          textContainer.style.marginRight = "10px";
          textContainer.style.textAlign = "justify";
          textContainer.style.lineHeight = "1.2";

          // text span styling
          textSpan.style.fontSize = "10px";
          textSpan.textContent = environment.fundingText;
        }

        // append elements to have image first
        logoContainer.appendChild(img);
        div.appendChild(logoContainer);
        textContainer.appendChild(textSpan);
        div.appendChild(textContainer);

        return div;
      };
      this._map.addControl(eu_logo);
    }

    L.Map = L.Map.extend({
      openPopup: function (popup) {
        this.closePopup();
        this._popup = popup;
        this._logger.log("MapComponent/popup ", popup);
        return this.addLayer(popup).fire("popupopen", {
          popup: this._popup,
        });
      },
    });

    L.Control = L.Control.extend({
      delete: function (popup) {
        this._popup = popup;
        return this.addLayer(popup).fire("popupDelete", {
          popup: this._popup,
        });
      },
    });
    L.control.scale().addTo(this._map);

    L.control.zoom({ position: "bottomright" }).addTo(this._map);

    return this._map;
  }

  getMap(): Map {
    return this._map;
  }
  openFeedback() {
    this.feedBackExpanded = true;
  }
}
