/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 31.05.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/

import { NgForOf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GeocodingService } from "@core/services/geocoding.service";
import { LoaderService } from "@core/services/loader.service";
import { Logger } from "@core/services/logger.service";
import { MapService } from "@pages/map/services/map.service";
import * as L from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";

@Component({
  standalone: true,
  selector: "hmt-search-bar",
  templateUrl: "./searchbar.component.html",
  styleUrls: ["./searchbar.component.scss"],
  imports: [NgForOf, FormsModule, ReactiveFormsModule],
})
export class SearchBarComponent implements OnInit {
  address: string;

  private _map: L.Map;
  public results = [];

  constructor(
    private _geocoder: GeocodingService,
    private _mapService: MapService,
    private _logger: Logger,
    private _loaderService: LoaderService
  ) {
    this.address = "";
  }

  ngOnInit() {
    this._map = this._mapService.getMap();
  }

  async options(value) {
    let isSelection = false;
    if (this.results.some((obj) => obj.label == value)) {
      isSelection = true;
      this.onSelectResult(value);
    }
    const provider = new OpenStreetMapProvider({
      params: {
        "accept-language": "en", // render results in English
        countrycodes:
          "al,ad,at,az,by,be,ba,bg,hr,cy,cz,dk,ee,fi,fr,ge,de,gr,hu,is,ie,it,kz,xk,lv,li,lt,lu,mk,mt,md,mc,me,nl,no,pl,pt,ro,ru,sm,rs,sk,si,es,se,ch,tr,ua,gb,va", // limit search results to Canada & United States
      },
    });
    if (isSelection) {
      this.results = [];
    } else {
      this.results = await provider.search({ query: value });
    }
  }

  onSelectResult(result: any) {
    this.address = result;
    this.goto();
  }
  goto() {
    // Track searchbar usage
    let searchbar = document.getElementById("place-input") as HTMLElement;
    if(searchbar.getAttribute("onblur") != null)
    {
      searchbar.removeAttribute("onblur");
      searchbar.setAttribute("onblur","_paq.push(['trackEvent', 'Search', 'Use searchbar', '"+this.address+" ']);");
    }
    else
    {
      searchbar.setAttribute("onblur","_paq.push(['trackEvent', 'Search', 'Use searchbar', '"+this.address+" ']);");
    }
    searchbar.blur();
    searchbar.removeAttribute("onblur");

    this._logger.log("NavigatorComponent/goto");
    if (!this.address) {
      return;
    }
    this._loaderService.display(true);
    this._logger.log(
      "NavigatorComponent/goto this.address!=null",
      this.address
    );
    this._geocoder.geocode(this.address).subscribe(
      (location) => {
        this._logger.log("NavigatorComponent/goto location", location);
        this._map.fitBounds(location.viewBounds, {});
        this.address = location.address;
        this._loaderService.display(false);
      },
      (error) => this._loaderService.display(false)
    );
  }

  recenterMap() {
    this._mapService.recenter();
  }

  searchDestinationFrom(address: string) {
    this._logger.log("searchbar.component/searchDestinationFrom");
    if (!address) {
      return;
    }
    this._loaderService.display(true);
    this._logger.log(
      "searchbar.component/searchDestinationFrom address!=null",
      this.address
    );
    this._geocoder.geocode(address).subscribe(
      (location) => {
        this._map.fitBounds(location.viewBounds, {});
        this.address = location.address;
        this._loaderService.display(false);
      },
      (error) => this._loaderService.display(false)
    );
  }
}
