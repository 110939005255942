import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIService } from "@services/api.service";
import { isNullOrUndefinedString } from "@services/core.utilities";
import { apiUrl } from "@services/data.service";
import { LoaderService } from "@services/loader.service";
import { Logger } from "@services/logger.service";
import { ToasterService } from "@services/toaster.service";
import { environment } from "environments/environment";
import { CalculationModuleClass } from "./calculation-module.class";

@Injectable()
export class CalculationModuleService extends APIService {
  categories = new Set();
  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService
  ) {
    super(http, logger, loaderService, toasterService);
  }

  getcalculationModuleServicesPromise(): Promise<CalculationModuleClass[]> {
    return Promise.resolve(this._getCalculationModuleServices()).then(
      (cmsArray) =>
        cmsArray.filter((cm) => environment.cmsId.includes(cm.cm_id))
    );
  }

  private _getCalculationModuleServices(): Promise<any> {
    return super.POST("", apiUrl + "/cm/list");
  }

  getCalculationModuleComponents(cmId): Promise<any> {
    this.logger.log("getCalculationModuleComponents id", cmId);
    const payload = { cm_id: "" + cmId };
    return super.POST(payload, apiUrl + "/cm/user-interface/");
  }

  getCalculationModuleCategories(cms) {
    this.categories.clear();
    cms.forEach((cm) => {
      if (cm.isReadable) {
        this.categories.add(cm.category);
      }
    });
    return Promise.resolve(Array.from(this.categories.values()));
  }
  getCMInformations(payload) {
    this.logger.log("getCMInformations compute-async/data", payload);
    return super.POST(payload, apiUrl + "/cm/compute-async/");
  }
  getStatusOfCM(status_id) {
    this.logger.log("getStatusOfCM", apiUrl + "/cm/status/" + status_id);

    if (isNullOrUndefinedString(status_id)) {
      this.logger.log("getStatusOfCM", "is  undefine");
    } else {
      this.logger.log("getStatusOfCM", "is  ok undefine");
    }
    return super
      .GET(apiUrl + "/cm/status/" + status_id)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError.bind(this));
  }

  deleteCM(id) {
    return super.DELETE(apiUrl + "/cm/delete/" + id);
  }
}
