import { Pipe, PipeTransform } from "@angular/core";
import { BusinessInterfaceRenderService } from "@core/services/business/business.service";

@Pipe({
  standalone: true,
  name: "businessName",
})
export class BusinessNamePipe implements PipeTransform {
  constructor(private _businessService: BusinessInterfaceRenderService) {}

  transform(value: any): string {
    if (value === null) {
      return "Nothing to transform";
    }

    // @TODO : See if reference change needed
    value = this._businessService.getReadableName(value);
    return value;
  }
}
