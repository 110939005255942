import { NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { GdprComponent } from "@components/gdpr/gdpr.component";
import { CookiesService } from "@core/services/cookies.service";
import { InteractionService } from "@core/services/interaction.service";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import * as uikit from "uikit";
import { AccountComponent } from "../account/account.component";
import { ActivateComponent } from "../activate/activate.component";
import { LoginComponent } from "../login/login.component";
import { RecoveryComponent } from "../recovery/recovery.component";
import { RegisterComponent } from "../register/register.component";
import { UserManagementStatusService } from "../service/user-management-status.service";
import { AcceptCookiesComponent } from "@components/accept-cookies/accept-cookies.component";

@Component({
  standalone: true,
  selector: "htm-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.css"],
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,

    // External Modules
    RecaptchaModule,
    RecaptchaFormsModule,

    // Components
    GdprComponent,
    GdprComponent,
    LoginComponent,
    AccountComponent,
    RegisterComponent,
    RecoveryComponent,
    ActivateComponent,
    UserManagementComponent,
    AcceptCookiesComponent,
  ],
})
export class UserManagementComponent implements OnInit {
  isLoggedIn;
  public username = "";
  public token = "";
  token_activation = "";
  token_recover = "";
  submitedRecover = false;
  constructor(
    private _userManagementStatusService: UserManagementStatusService,
    private _interactionService: InteractionService,
    private _route: ActivatedRoute,
    public cookies: CookiesService
  ) {}

  ngOnInit() {
    this.subscribe();
  }
  subscribe() {
    this._route.params.subscribe((params) => {
      if (params.token_activation) {
        this.token_activation = params.token_activation;
        uikit.modal("#modal-activate").show();
      } else if (params.token_recover) {
        this.token_recover = params.token_recover;
        this.submitedRecover = true;
        uikit.modal("#modal-recover").show();
      }
    });

    if (this._userManagementStatusService.getIsUserLogged) {
      this._userManagementStatusService.getIsUserLogged().subscribe((data) => {
        this.isLoggedIn = data;
        if (this.isLoggedIn) {
          this.closeLogin();
        } else this.closeAccount();
      });
    }

    if (this._userManagementStatusService.getUserToken)
      this._userManagementStatusService
        .getUserToken()
        .subscribe((data) => (this.token = data));

    if (this._userManagementStatusService.getUsername)
      this._userManagementStatusService
        .getUsername()
        .subscribe((data) => (this.username = data));
  }

  closeAccount() {
    uikit.modal("#modal-account").hide();
  }
  closeLogin() {
    uikit.modal("#modal-login").hide();
  }
}
