import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable()
export class ScenarioService {
  constructor(private _http: HttpClient) {}

  public getScenarios() {
    const url = `${environment.apiUrl}/openentrance/scenarios`;

    return this._http.get<any[]>(url);
  }
}
