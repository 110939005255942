<h2 class="uk-modal-title">RECOVER ACCOUNT</h2>
<p *ngIf="!submitedRecover">
  <input
    id="recover-email-input"
    class="uk-input"
    [class.uk-form-danger]="isEmailInvalid"
    type="text"
    placeholder="Email address"
    (keyup.enter)="!waitingStatus ? recoverAccountAsk() : null"
    (blur)="onInputBlur($event)"
    [(ngModel)]="email"
    [value]="email"
    required
  />
</p>
<p *ngIf="!submitedRecover">
  <button class="uk-button uk-button-default uk-modal-close" type="button">
    Cancel
  </button>
  <button
    class="uk-button uk-button-primary"
    type="button"
    (click)="!waitingStatus ? recoverAccountAsk() : null"
  >
    <span *ngIf="!waitingStatus">Recover account</span>
    <span *ngIf="waitingStatus" uk-spinner></span>
  </button>
</p>
<ng-container *ngIf="errorStr.length > 0">
  <div class="uk-text-danger">
    {{ errorStr }}
  </div>
</ng-container>

<p *ngIf="submitedRecover">Enter your new password</p>
<p *ngIf="submitedRecover">
  <input
    class="uk-input"
    type="password"
    placeholder="New password"
    (keyup.enter)="!waitingStatus ? recoverAccount() : null"
    [(ngModel)]="new_password"
    [value]="new_password"
    required
  />
</p>
<p *ngIf="submitedRecover">
  <button class="uk-button uk-button-default uk-modal-close" type="button">
    Cancel
  </button>
  <button
    class="uk-button uk-button-primary"
    type="button"
    (click)="!waitingStatus ? recoverAccount() : null"
  >
    <span *ngIf="!waitingStatus">Submit</span>
    <span *ngIf="waitingStatus" uk-spinner></span>
  </button>
</p>
